import React, { useState, useEffect } from "react";
import { Row, Col, ListGroup, Card, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import ChangePasswordScreen from "./ChangePasswordScreen";
import MyProfileEditScreen from "./MyProfileEditScreen";
import { useGetMyDetailsQuery } from "../slices/associatesSlice";
import { setCredentials } from "../slices/authSlice";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  useGetMyGymQuery,
  useUpdateUsersTokenMutation,
} from "../slices/gymSlice";
import VerticallyCenteredModal from "../components/SubmitModal";
import { toast } from "react-toastify";

const MyProfileScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const pageDomain = window.location.origin; // to get domain
  const [editable, setEditable] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  // const [changeUrl, setChangeUrl] = useState(false);

  const dispatch = useDispatch();

  const [updateGym] = useUpdateUsersTokenMutation();

  let {
    data: myDetails,
    isLoading,
    error,
    refetch,
  } = useGetMyDetailsQuery({
    token: userInfo.token,
  });

  let {
    data: myGym,
    isLoading: isGymLoading,
    error: errorInMyGym,
    refetch: refetchGym,
  } = useGetMyGymQuery({
    token: userInfo.token,
  });

  useEffect(() => {
    refetchGym();
  }, [refetchGym]);

  useEffect(() => {
    refetch();
    if (myDetails)
      dispatch(
        setCredentials({
          ...myDetails,
          token: userInfo.token,
          audioOn: userInfo.audioOn,
          visitorsStartDate: userInfo.visitorsStartDate,
          visitorsEndDate: userInfo.visitorsEndDate,
          attendancesStartDate: userInfo.attendancesStartDate,
          attendancesEndDate: userInfo.attendancesEndDate,
          paymentsStartDate: userInfo.paymentsStartDate,
          paymentsEndDate: userInfo.paymentsEndDate,
          dashboardStartDate: userInfo.dashboardStartDate,
          dashboardEndDate: userInfo.dashboardEndDate,
        })
      );
  }, [myDetails, refetch, dispatch, editable]);

  const handleEditStatus = () => {
    setEditable(false);
  };

  const handleChangePassword = () => {
    setChangePassword(false);
  };

  const submitEdit = async () => {
    try {
      await updateGym({
        gymId: userInfo.gymId._id,
        token: userInfo.token,
      }).unwrap();

      toast.success("URL is updated");
    } catch (e) {
      toast.error(e?.data?.error || e.data);
    }
  };

  const handleSubmit = async (user) => {
    setModalShow(true);
  };

  const copyUrlClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("URL is copied");
      })
      .catch((err) => {
        toast.error("copy failed");
        // Handle errors, such as permissions denied
      });
  };

  return (
    <>
      <VerticallyCenteredModal
        title="Are you sure want to Change URL ?"
        show={modalShow}
        size="sm"
        onHide={() => setModalShow(false)}
        onSubmit={submitEdit}
      />

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message>{error?.data?.error}</Message>
      ) : (
        <>
          {" "}
          {changePassword ? (
            <ChangePasswordScreen
              user={userInfo}
              changePassword={handleChangePassword}
            ></ChangePasswordScreen>
          ) : editable ? (
            <MyProfileEditScreen
              associate={myDetails}
              editable={handleEditStatus}
            />
          ) : (
            <>
              <h5>
                My Profile : {userInfo.firstName} {userInfo.lastName}
              </h5>

              <Row style={{ margin: "2%" }}>
                <Button
                  className=" marginPointEight sixteenWidth buttonPeach twoButtonInRow"
                  onClick={(e) => setEditable(true)}
                >
                  Edit
                </Button>

                <Button
                  className=" marginPointEight sixteenWidth buttonTeal twoButtonInRow"
                  onClick={(e) => setChangePassword(true)}
                >
                  Change Password
                </Button>
              </Row>

              <Row>
                <Col md={5}>
                  <Card>
                    <ListGroup>
                      <ListGroup.Item>
                        <Row>
                          <Col>User ID : </Col>
                          <Col> {userInfo.associateId}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col> User Role : </Col>
                            <Col> {userInfo.role}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> Gender : </Col>
                          <Col> {userInfo.gender}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> Work Shift : </Col>
                          <Col> {userInfo.workShift}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col> Joined Date : </Col>
                            <Col> {userInfo.joinedDate?.split("T")[0]}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col> Date Of Birth : </Col>
                            <Col> {userInfo.dateOfBirth?.split("T")[0]}</Col>
                          </Row>
                        }
                      </ListGroup.Item>

                      <ListGroup.Item>
                        <Row>
                          <Col> Contact Number: </Col>
                          <Col> {userInfo.mobileNumber}</Col>
                        </Row>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Col>
                <Col md={5}>
                  <Card>
                    <ListGroup>
                      <ListGroup.Item>
                        <Row>
                          <Col> Alternate Number : </Col>
                          <Col> {userInfo.alternateNumber}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> Email : </Col>
                          <Col> {userInfo.email}</Col>
                        </Row>
                      </ListGroup.Item>

                      <ListGroup.Item>
                        {
                          <Row>
                            <Col> PAN Number : </Col>
                            <Col> {userInfo.panNumber}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col> Aadhar Number : </Col>
                            <Col> {userInfo.aadharNumber}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> Address : </Col>
                          <Col> {userInfo.address}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col> Pincode : </Col>
                            <Col> {userInfo.pincode}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Col>
              </Row>

              {userInfo.gymId.subscriptionType !== "Premium" &&
              myGym?.tokenForUsers ? (
                <Col md={5} style={{ marginTop: "3%" }}>
                  <Card>
                    <ListGroup>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {isGymLoading ? (
                              <Loader />
                            ) : errorInMyGym ? (
                              <></>
                            ) : (
                              <label>
                                <strong>
                                  {pageDomain}/usersAtGym/{myGym.tokenForUsers}
                                </strong>{" "}
                              </label>
                            )}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {" "}
                            <button
                              onClick={() =>
                                copyUrlClipboard(
                                  `${pageDomain}/usersAtGym/${myGym.tokenForUsers}`
                                )
                              }
                            >
                              copy url
                            </button>
                          </Col>
                          <Col>
                            {" "}
                            <button onClick={handleSubmit}>update url</button>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Col>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default MyProfileScreen;
