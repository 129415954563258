import { React, useEffect, useState } from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { Pencil, CheckLg, XLg } from "react-bootstrap-icons";
import { useSelector, useDispatch } from "react-redux";
import * as XLSX from "xlsx"; // Importing xlsx library

import Loader from "../components/Loader";
import Message from "../components/Message";
import VerticallyCenteredModal from "../components/SubmitModal";
import FieldWarning from "../components/FieldWarning";
import { setCredentials } from "../slices/authSlice";

import {
  useCreateVisitorMutation,
  useGetVisitorsQuery,
  useUpdateVisitorMutation,
} from "../slices/visitorsSlice";
import { toast } from "react-toastify";
import { getCurrentDate, getCurrentTime } from "../utils/getCurrentDate";

const VisitorsScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [editEnabled, setEditEnabled] = useState("");
  const [visitorName, setVisitorName] = useState("");
  const [visitorMobile, setVisitorMobile] = useState("");
  const [visitedOn, setVisitedOn] = useState(getCurrentDate());
  const [visitedStatus, setVisitedStatus] = useState("");
  const [notes, setNotes] = useState("");

  const [visitorNamePresent, setVisitorNamePresent] = useState("");
  const [visitorMobilePresent, setVisitorMobilePresent] = useState("");
  const [visitedOnPresent, setVisitedOnPresent] = useState();
  const [visitedStatusPresent, setVisitedStatusPresent] = useState("");
  const [notesPresent, setNotesPresent] = useState("");

  const [modalShow, setModalShow] = useState(false);

  const [filterValue, setFilterValue] = useState("");

  const [startDate, setStartDate] = useState(userInfo.visitorsStartDate);

  const [endDate, setEndDate] = useState(userInfo.visitorsEndDate);

  const [getApiQuery, setGetApiQuery] = useState(
    `visitedDate[gte]=${startDate}T00:00:00.000Z&visitedDate[lte]=${endDate}${filterValue}`
  );
  const [newUserAdded, setNewUserAdded] = useState("");
  const [todaysVisitorsCount, setTodaysVisitorsCount] = useState("");

  const dispatch = useDispatch();

  const filterOptions = [
    "&status=All",
    "&status=Visited",
    "&status=Contacted",
    "&status=Joined",
    "&status=Not Joining",
  ];

  let {
    data: visitors,
    isLoading,
    error,
    refetch,
  } = useGetVisitorsQuery({
    token: userInfo.token,
    query: getApiQuery,
  });

  setInterval(() => {
    setNewUserAdded(Date.now());
  }, 600000);

  const [updateVisitor] = useUpdateVisitorMutation();
  const [createVisitor] = useCreateVisitorMutation();

  useEffect(() => {
    if (visitorName?.length > 0) {
      setVisitorNamePresent(false);
    }

    if (visitorMobile?.length === 10 && Number(visitorMobile) > 0) {
      setVisitorMobilePresent(false);
    }

    if (visitedOn <= getCurrentDate()) {
      setVisitedOnPresent(false);
    }

    if (visitedStatus !== undefined || visitedStatus?.length > 0) {
      setVisitedStatusPresent(false);
    }

    if (notes?.length < 1000) {
      setNotesPresent(false);
    }
  }, [visitorName, visitorMobile, visitedOn, visitedStatus, notes]);

  const verifyInputFeilds = async () => {
    let fieldValidationSuccess = true;

    if (visitorName?.length < 1 || visitorName?.length > 100) {
      setVisitorNamePresent(true);
      fieldValidationSuccess = false;
    }
    if (visitorMobile?.length !== 10) {
      setVisitorMobilePresent(true);
      fieldValidationSuccess = false;
    }

    if (visitedOn?.split("T")[0] > getCurrentDate()) {
      setVisitedOnPresent(true);
      fieldValidationSuccess = false;
    }

    if (notes?.length > 1000) {
      setNotesPresent(true);
      fieldValidationSuccess = false;
    }

    if (!fieldValidationSuccess)
      toast.error(
        "Some of the fields have invalid inputs. Please provide valid Visitor details"
      );
    return fieldValidationSuccess;
  };

  const handleAddVisitor = async (user) => {
    if (await verifyInputFeilds())
      try {
        const resData = await createVisitor({
          token: userInfo.token,
          payload: {
            gymId: userInfo.gymId._id,
            name: visitorName,
            mobileNumber: visitorMobile,
            visitedDate: visitedOn,
            notes,
          },
        }).unwrap();
        setNewUserAdded(resData);
        setVisitorName("");
        setVisitedOn(getCurrentDate());
        setEditEnabled("");
        setVisitedStatus("");
        setVisitorMobile("");
        setNotes("");
        toast.success("Visitor added");
      } catch (e) {
        toast.error(e?.data?.error || e.data);
      }
  };

  const submitEdit = async () => {
    try {
      await updateVisitor({
        _id: editEnabled,
        token: userInfo.token,
        payload: {
          name: visitorName,
          mobileNumber: visitorMobile,
          visitedDate: visitedOn,
          status: visitedStatus,
          notes,
        },
      }).unwrap();
      setVisitorName("");
      setEditEnabled("");
      setVisitedOn(getCurrentDate());
      setVisitedStatus("");
      setVisitorMobile("");
      setNotes("");
      toast.success("Visitor updated");
    } catch (e) {
      toast.error(e?.data?.error || e.data);
    }
  };

  const hanbdleSubmit = async (user) => {
    if (await verifyInputFeilds()) setModalShow(true);
  };

  const handleGetVisitors = async () => {
    if (startDate > getCurrentDate())
      toast.error("Start date can not be greater than today");
    else if (startDate > endDate)
      toast.error("Start date can not be greater than End date");
    else {
      if (endDate?.split("T")[0] > getCurrentDate())
        setEndDate(getCurrentDate() + getCurrentTime());
      // setEndDate(getCurrentDate() + getCurrentTime());
      setGetApiQuery(
        `visitedDate[gte]=${startDate}T00:00:00.000Z&visitedDate[lte]=${endDate}${filterValue}`
      );
      dispatch(
        setCredentials({
          ...userInfo,
          visitorsStartDate: startDate,
          visitorsEndDate: endDate,
        })
      );
    }
  };

  useEffect(() => {
    refetch();
  }, [refetch, editEnabled, newUserAdded]);

  useEffect(() => {
    const todaysVisitCount = isLoading
      ? 0
      : error
      ? 0
      : visitors
      ? visitors?.length
      : 0;
    setTodaysVisitorsCount(todaysVisitCount);
  }, [setTodaysVisitorsCount, visitors, isLoading, error]);

  const handleSelect = (e) => {
    setVisitedStatus(e.target.value);
  };

  const handleStatusFilter = (val) => {
    if (val.includes("All")) setFilterValue("");
    else setFilterValue(val);
  };

  const hanbdleCancelEdit = () => {
    setEditEnabled(false);
  };

  const exportToExcel = () => {
    if (error) {
      toast.error(
        "No Visitors available to Download for the selected Date range"
      );
    } else {
      const formattedData = visitors?.map((visitor) => ({
        "Visitor Name": visitor.name,
        "Mobile Number": visitor.mobileNumber,
        "Visited Date": visitor.visitedDate
          ? visitor.visitedDate.split("T")[0]
          : visitor.visitedDate.split("T")[0],
        Status: visitor.status,
        Notes: visitor.notes,
        "Associate Id": visitor.associateId,
      }));

      const workSheet = XLSX.utils.json_to_sheet(formattedData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Visitors List");
      XLSX.writeFile(
        workBook,
        "Visitors from " + startDate + " to " + endDate + ".xlsx"
      );
    }
  };

  return (
    <>
      {userInfo.gymId.subscriptionType !== "Premium" ? (
        <>
          <h5> Visitor's details</h5>
          <VerticallyCenteredModal
            title="Are you sure want to submit this ?"
            show={modalShow}
            size="sm"
            onHide={() => setModalShow(false)}
            onSubmit={submitEdit}
            setEdit={setEditEnabled}
          />
          {editEnabled ? (
            ""
          ) : (
            <Row
              className="mb-0"
              style={{
                margin: "auto",
                width: "95%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Col xs={6} md="auto" sm={6}>
                <Form.Group controlId="visitorName">
                  <Form.Control
                    type="text"
                    value={visitorName}
                    onChange={(e) => setVisitorName(e.target.value)}
                    placeholder="Visitor Name*"
                  />
                </Form.Group>
                {visitorNamePresent ? (
                  <FieldWarning text="Please Visitor name" />
                ) : (
                  ""
                )}
              </Col>
              <Col xs={6} md="auto" sm={6}>
                <Form.Group controlId="visitorMobile">
                  <Form.Control
                    type="text"
                    pattern="[0-9]*" // Allow only digits
                    value={visitorMobile}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                      setVisitorMobile(value);
                    }}
                    placeholder="Visitor's Mobile*"
                  />
                </Form.Group>
                {visitorMobilePresent ? (
                  <FieldWarning text="Please provide 10 digit mobile number" />
                ) : (
                  ""
                )}
              </Col>
              <Col xs={6} md="auto" sm={6}>
                <Form.Group controlId="visitedDate">
                  <Form.Control
                    type="date"
                    value={visitedOn}
                    onChange={(e) => setVisitedOn(e.target.value)}
                    placeholder="Visited Date"
                  />
                </Form.Group>
                {visitedOnPresent ? (
                  <FieldWarning text="Please provide valid date in format DD/MM/YYYY" />
                ) : (
                  ""
                )}
              </Col>
              <Col xs={6} md="auto" sm={6}>
                <Form.Group controlId="notes">
                  <Form.Control
                    type="text"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    placeholder="Notes"
                  />
                </Form.Group>
                {notesPresent ? (
                  <FieldWarning text="notes should not have more then 1000 chars" />
                ) : (
                  ""
                )}
              </Col>

              <Col
                xs={12}
                md="auto"
                sm={6}
                className="d-flex justify-content-center"
              >
                <Form.Group className="my-1" controlId="notes">
                  <Button onClick={handleAddVisitor}>Add Visitor</Button>
                </Form.Group>
              </Col>
            </Row>
          )}

          <Row
            className="mb-2"
            style={{
              margin: "auto",
              width: "95%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col xs={6} md="auto" sm={6}>
              <Form.Group controlId="visitorMobile">
                <Form.Control
                  type="date"
                  placeholder="2023-11-23"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col xs={6} md="auto" sm={6}>
              <Form.Group controlId="visitorMobile">
                <Form.Control
                  type="date"
                  placeholder="2023-11-27"
                  value={endDate?.split("T")[0]}
                  onChange={(e) => setEndDate(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col xs={12} md="auto" sm={6}>
              <Form.Group controlId="filterBy">
                <Form.Select
                  id="filterBy"
                  value={filterValue}
                  onChange={(e) => handleStatusFilter(e.target.value)}
                >
                  {filterOptions.map((e) => (
                    <option key={e} value={e}>
                      {e?.split("=")[1]}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col
              xs={6}
              md="auto"
              sm={6}
              className="d-flex justify-content-center"
            >
              <Form.Group className="my-1" controlId="gymType">
                <Button
                  id="filterBy"
                  value={filterValue}
                  onClick={handleGetVisitors}
                >
                  Get Visitors
                </Button>
              </Form.Group>
            </Col>
            <Col xs={12} md="auto" sm={6}>
              <Form.Group as={Row} controlId="visitorMobile">
                <Button style={{ backgroundColor: "#27aa67" }}>
                  Total Visitors :<strong> {todaysVisitorsCount}</strong>
                </Button>
              </Form.Group>
            </Col>
            <Col
              xs={6}
              md="auto"
              sm={6}
              className="d-flex justify-content-center"
            >
              <Button onClick={exportToExcel} variant="success">
                Download Visitors
              </Button>
            </Col>
          </Row>
          {isLoading ? (
            <Loader />
          ) : error ? (
            <Message>{error?.data?.error}</Message>
          ) : (
            <>
              <Table striped bordered hover responsive="sm">
                <thead>
                  <tr>
                    <th> Name</th>
                    <th>Mobile</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Notes</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {visitors ? (
                    visitors.map((user) => (
                      <>
                        {editEnabled === user._id ? (
                          <tr>
                            <td>
                              <Form.Control
                                value={visitorName}
                                type="text"
                                onChange={(e) => setVisitorName(e.target.value)}
                              ></Form.Control>
                              {visitorNamePresent ? (
                                <FieldWarning text="Please Visitor name" />
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              <Form.Control
                                type="text"
                                pattern="[0-9]*" // Allow only digits
                                value={visitorMobile}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  ); // Remove non-numeric characters
                                  setVisitorMobile(value);
                                }}
                              />
                              {visitorMobilePresent ? (
                                <FieldWarning text="Please provide 10 digit mobile number" />
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              <Form.Control
                                value={visitedOn?.split("T")[0]}
                                type="date"
                                onChange={(e) => setVisitedOn(e.target.value)}
                              ></Form.Control>

                              {visitedOnPresent ? (
                                <FieldWarning text="Please provide valid date in format DD/MM/YYYY" />
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              <Form.Select
                                id="searchBy"
                                value={
                                  visitedStatus ? visitedStatus : user.status
                                }
                                onChange={handleSelect}
                                style={{ height: "100%" }}
                              >
                                <option value={user.status}>
                                  {user.status}
                                </option>
                                <option value="Contacted">Contacted</option>
                                <option value="Joined">Joined</option>
                                <option value="Not Joining">Not Joining</option>
                              </Form.Select>
                              {visitedStatusPresent ? (
                                <FieldWarning text="Please select valid status" />
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              <Form.Control
                                value={notes}
                                type="text"
                                onChange={(e) => setNotes(e.target.value)}
                              ></Form.Control>
                              {notesPresent ? (
                                <FieldWarning text="notes should not have more then 1000 chars" />
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              {editEnabled ? (
                                <>
                                  <button
                                    onClick={hanbdleSubmit}
                                    style={{
                                      borderRadius: "10%",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <CheckLg />
                                  </button>
                                  <button
                                    onClick={hanbdleCancelEdit}
                                    style={{
                                      borderRadius: "10%",
                                      margin: "8%",
                                    }}
                                  >
                                    <XLg />
                                  </button>
                                </>
                              ) : (
                                <></>
                              )}
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td>{user.name}</td>
                            <td>{user.mobileNumber}</td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {user.visitedDate
                                ? user.visitedDate?.split("T")[0]
                                : ""}
                            </td>
                            <td>{user.status}</td>
                            <td>{user.notes}</td>

                            <td>
                              <button
                                style={{ borderRadius: "20%" }}
                                onClick={() => {
                                  setVisitorName(user.name);
                                  setEditEnabled(user._id);

                                  setNotes(user.notes);
                                  setVisitorMobile(user.mobileNumber);
                                  setVisitedOn(user.visitedDate);
                                  setVisitedStatus(user.status);
                                }}
                              >
                                <Pencil />
                              </button>
                            </td>
                          </tr>
                        )}
                      </>
                    ))
                  ) : (
                    <Message>No visitors Available</Message>
                  )}
                </tbody>
              </Table>
            </>
          )}
        </>
      ) : (
        <div style={{ textAlign: "center", justifyContent: "center" }}>
          <label style={{ color: "red" }}>
            This feature is available only for Premium Plus.
          </label>
          <br></br>
          <br></br>
          <h5>
            Today's visitors are tomorrow's customers. Why lose your new
            Customer?{" "}
          </h5>
          <h6>
            Please upgrade to Premium Plus to keep track of all your Visitors
            and get a chance to turn your Visitors into Customers
          </h6>
        </div>
      )}
    </>
  );
};

export default VisitorsScreen;
