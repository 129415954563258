import React from "react";
import QRCode from "qrcode.react";

function QRCodeGenerator({ content }) {
  return (
    <div>
      <QRCode value={content} style={{ width: "60%", height: "70%" }} />
    </div>
  );
}

export default QRCodeGenerator;
