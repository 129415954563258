import { Button, Form, Row, Col } from "react-bootstrap";

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import FieldWarning from "../components/FieldWarning";
import VerticallyCenteredModal from "../components/SubmitModal";
import { useUpdateMyGymMutation } from "../slices/gymSlice";
import FormContainer from "../components/FormContainer";
import { useUpdateGymPaymentMutation } from "../slices/gymPaymentSlice";

const GymPaymentUpdateScreen = ({ associate: gymDetails, updatePayment }) => {
  // const [regCode, setRegCode] = useState();
  const { userInfo } = useSelector((state) => state.auth);
  const gymId = gymDetails._id;
  const [transactionId, setTransactionId] = useState("");
  console.log(gymDetails);
  const dueAmount = gymDetails.dueAmount;
  const [payingAmount, setPayingAmount] = useState();

  const [modalShow, setModalShow] = useState(false);

  const [payingAmountPresent, setPayingAmountPresent] = useState(false);
  const [transactionIdPresent, setTransactionIdPresent] = useState("");

  const [updateGymPayment, { isLoading }] = useUpdateGymPaymentMutation();
  const [updateGymDetails] = useUpdateMyGymMutation();

  useEffect(() => {
    if (payingAmount <= dueAmount && payingAmount > 1) {
      setPayingAmountPresent(false);
    }
    if (transactionId?.length > 5) {
      setTransactionIdPresent(false);
    }
  }, [dueAmount, payingAmount, payingAmountPresent, transactionId]);

  const verifyInputFeilds = async () => {
    let fieldValidationSuccess = true;

    if (payingAmount > dueAmount || payingAmount < 1) {
      setPayingAmountPresent(true);
      fieldValidationSuccess = false;
    }

    if (transactionId?.length <= 5) {
      setTransactionIdPresent(true);
      fieldValidationSuccess = false;
    }
    if (!fieldValidationSuccess)
      toast.error(
        "Some of the fields have invalid inputs. Please provide valid details"
      );
    return fieldValidationSuccess;
  };

  const submitHandler = async (e) => {
    // e.preventDefault();
    try {
      const data = {
        payload: {
          gymId,
          transactionId,
          dueAmount,
          paidAmount: payingAmount,
          pendingAmount: dueAmount - payingAmount,
        },
        token: userInfo.token,
      };
      await updateGymPayment(data).unwrap();
      if (data) {
        const data1 = {
          payload: {
            dueAmount: dueAmount - payingAmount,
          },
          token: userInfo.token,
        };
        await updateGymDetails(data1).unwrap();
      }
      updatePayment();
      toast.success("Payment updated successfuly ");
      // setFirstName("");
      // setLastName("");
      // setGender("Male");
      // setEmail("");
      // setConatctNumber("");
      // setAlternateNumber("");
      // setAddress("");
      // setPincode("");
      // setAadharNumber("");
      // setPanNumber("");
      // setDateOfBirth(getCurrentDate());
      // setJoinedDate(getCurrentDate());
      // navigate("/");
    } catch (e) {
      toast.error(e?.data?.error || e.data);
    }
  };

  const handleCancelButton = () => {
    // navigate(`/profile`);
    updatePayment();
  };

  const handleSubmit = async (user) => {
    if (await verifyInputFeilds()) setModalShow(true);
  };

  return (
    <>
      <VerticallyCenteredModal
        title="Are you sure want to add payment ?"
        size="sm"
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSubmit={submitHandler}
        // setEdit={() => paymentStatus(true)}
      />
      <FormContainer>
        <h4>Payment Details : </h4>
        <Form.Group as={Row} className="my-2" controlId="contactNumber">
          <Form.Label column="true" sm="6">
            Due Amount :
          </Form.Label>
          <Col sm="6">
            <Form.Label column="true" sm="6">
              {dueAmount}
            </Form.Label>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="contactNumber">
          <Form.Label column="true" sm="6">
            Amount <label style={{ color: "red" }}>*</label> :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="text"
              pattern="[0-9]*" // Allow only digits
              value={payingAmount}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                setPayingAmount(value);
              }}
              placeholder="enter amount"
            />
            {payingAmountPresent ? (
              <FieldWarning text="You can not pay Rs.0 or greater than due Amount" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="my-2" controlId="email">
          <Form.Label column="true" sm="6">
            Transacntion Id <label style={{ color: "red" }}>*</label> :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="text"
              value={transactionId}
              onChange={(e) => setTransactionId(e.target.value)}
              placeholder="enter Transacntion Id"
            ></Form.Control>
            {transactionIdPresent ? (
              <FieldWarning text="Please provide transaction Id or reference number of the paid amount" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>

        <Button
          type="submit"
          disabled={isLoading}
          variant="primary"
          onClick={handleSubmit}
        >
          {" "}
          Submit
        </Button>

        <Button
          style={{ marginLeft: "20%" }}
          type="button"
          disabled={isLoading}
          variant="secondary"
          onClick={handleCancelButton}
        >
          {" "}
          Cancel
        </Button>
      </FormContainer>
    </>
  );
};

export default GymPaymentUpdateScreen;
