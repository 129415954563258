export const getCurrentDate = () => {
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  // This arrangement can be altered based on how we want the date's format to appear.
  let currentDate = `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;
  return currentDate;
};

export const getDateOfLast = (days) => {
  let currentDate = new Date();
  let thirtyDaysAgo = new Date(currentDate);
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - days);
  let formattedDate = thirtyDaysAgo?.toISOString().split("T")[0];
  return formattedDate;
};

export const getCurrentTime = () => {
  const date = new Date();
  let hr = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  let min =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  let sec =
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  // This arrangement can be altered based on how we want the date's format to appear.
  let currenTime = `T${hr}:${min}:${sec}.000Z`;
  return currenTime;
};
