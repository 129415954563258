import { React, useEffect, useState } from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { Pencil, CheckLg, XLg } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useGetUsersQuery, useUpdateUserMutation } from "../slices/usersSlice";
import Loader from "../components/Loader";
import { setUsers } from "../slices/userSlice";
import Message from "../components/Message";
import { getCurrentDate } from "../utils/getCurrentDate";
import { setCredentials } from "../slices/authSlice";

import { toast } from "react-toastify";

const SubsExpiredUsersScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [searchBy, setSearchBy] = useState("Id");
  const [searchValue, setSearchValue] = useState("");
  const [editEnabled, setEditEnabled] = useState("");
  const [notes, setNotes] = useState("");
  const [apiQuery, setApiQuery] = useState(
    `?gymId=${
      userInfo.gymId._id
    }&subscriptionExpiryDate[lte]=${getCurrentDate()}&sort=-subscriptionExpiryDate`
  );

  const filterOptions = ["Id", "Name", "Mobile"];

  const dispatch = useDispatch();

  const searchOptions = {
    Id: "_id",
    Name: "firstName",
    Mobile: "mobileNumber",
  };

  let {
    data: users,
    isLoading,
    refetch,
    error,
  } = useGetUsersQuery({
    token: userInfo.token,
    query: apiQuery,
  });

  const [updateUser] = useUpdateUserMutation();

  const [totalUsers, setTotalUsers] = useState(
    isLoading ? [] : users ? users : []
  );
  const [usersToList, setUsersToList] = useState(
    isLoading ? [] : users ? users : []
  );
  const [activeUsers, setActiveUsers] = useState("");
  const [inActiveUsers, setInactiveUsers] = useState("");
  const [maleUsers, setMaleUsers] = useState("");
  const [femaleUsers, setfemaleUsers] = useState("");
  const [seniorCitizens, setSeniorCitizens] = useState("");
  const [ptEnablesUsers, setPtEnablesUsers] = useState("");

  useEffect(() => {
    refetch();
    if (users) dispatch(setUsers(users));
    dispatch(
      setCredentials({
        ...userInfo,
        lastVisitedPage: "/subsExpiredUsers",
      })
    );
    setTotalUsers(isLoading ? [] : users ? users : 0);
    setUsersToList(isLoading ? [] : users ? users : 0);
    setActiveUsers(
      isLoading ? [] : users ? users.filter((user) => user["isActive"]) : []
    );

    setInactiveUsers(
      isLoading ? [] : users ? users.filter((user) => !user["isActive"]) : []
    );

    setMaleUsers(
      isLoading
        ? []
        : users
        ? users.filter((user) => user["gender"] === "Male")
        : []
    );

    setfemaleUsers(
      isLoading
        ? []
        : users
        ? users.filter((user) => user["gender"] === "Female")
        : []
    );
    setSeniorCitizens(
      isLoading
        ? []
        : users
        ? users.filter(
            (user) =>
              50 <
              Math.floor(
                (new Date() - new Date(user["dateOfBirth"])) /
                  (365.25 * 24 * 60 * 60 * 1000)
              )
          )
        : []
    );

    setPtEnablesUsers(
      isLoading ? [] : users ? users.filter((user) => user["ptEnabled"]) : []
    );
  }, [refetch, dispatch, users, editEnabled]);

  const handleSelect = (e) => {
    setSearchBy(e.target.value);
  };
  const onSearchTextChange = (val) => {
    setSearchValue(val);
    const searchedUsers = users?.filter((user) => {
      let userData;
      if (searchBy === "Name") {
        userData = String(user[searchOptions[searchBy]]).toLowerCase();
      } else {
        userData = String(user[searchOptions[searchBy]]);
      }
      return userData.includes(val.toLowerCase());
    });
    setUsersToList(searchedUsers);
  };
  // const handleSearchButton = () => {
  //   const searchedUsers = users?.filter((user) => {
  //     let userData;
  //     if (searchBy === "Name") {
  //       userData = String(user[searchOptions[searchBy]]).toLowerCase();
  //     } else {
  //       userData = String(user[searchOptions[searchBy]]);
  //     }
  //     return userData.includes(searchValue.toLowerCase());
  //   });
  //   setUsersToList(searchedUsers);
  // };

  const handleFilterSelecttion = (val) => {
    setUsersToList(val);
  };

  const hanbdleSubmit = async (user) => {
    try {
      const userId = editEnabled?.toString().substring(4, 8);
      await updateUser({
        userId,
        token: userInfo.token,
        payLoad: {
          notes,
        },
      }).unwrap();
      setEditEnabled("");
      setNotes("");
      toast.success("User Details updated");
    } catch (e) {
      toast.error(e?.data?.error || e.data);
    }
  };
  const hanbdleCancelEdit = () => {
    setEditEnabled(false);
  };

  return (
    <>
      <h5>Subscription Expired Users</h5>
      <Row
        className="mb-3"
        style={{
          justifyContent: "center",
          padding: "auto",
          margin: "auto",
          width: "95%",
          alignItems: "center",
        }}
      >
        <Col>
          <Form.Group as={Row} className="my-2" controlId="filterBy">
            <Form.Select id="filterBy" value={searchBy} onChange={handleSelect}>
              {filterOptions.map((e) => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Row} className="my-2" controlId="visitorMobile">
            <Form.Control
              type="text"
              placeholder="type here to search"
              value={searchValue}
              onChange={(e) => onSearchTextChange(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Row} className="my-2" controlId="visitorMobile">
            <Button
              type="date"
              placeholder="2023-11-27"
              value={searchValue}
              onChange={(e) => onSearchTextChange(e.target.value)}
            >
              Search
            </Button>
          </Form.Group>
        </Col>
      </Row>
      <Row className="dataRow" style={{ marginBottom: "2%" }}>
        <Button
          className="marginPointEight fifteenWidth buttonGreen numbersButton"
          onClick={() => handleFilterSelecttion(totalUsers)}
        >
          All <strong> {totalUsers?.length}</strong>
        </Button>
        <Button
          className="marginPointOne fifteenWidth buttonPeach numbersButton"
          onClick={() => handleFilterSelecttion(activeUsers)}
        >
          Active <strong> {activeUsers?.length}</strong>
        </Button>
        <Button
          className="marginPointOne fifteenWidth buttonReddish numbersButton"
          onClick={() => handleFilterSelecttion(inActiveUsers)}
        >
          InActive <strong> {inActiveUsers?.length}</strong>
        </Button>
        <Button
          className="marginPointOne fifteenWidth buttonTeal numbersButton"
          onClick={() => handleFilterSelecttion(maleUsers)}
        >
          Male <strong> {maleUsers?.length}</strong>
        </Button>
        <Button
          className="marginPointOne fifteenWidth buttonMaroon numbersButton"
          onClick={() => handleFilterSelecttion(femaleUsers)}
        >
          Female <strong> {femaleUsers?.length}</strong>
        </Button>
        <Button
          className="marginPointOne fifteenWidth buttonOlive numbersButton"
          onClick={() => handleFilterSelecttion(seniorCitizens)}
        >
          Seniors <strong> {seniorCitizens?.length}</strong>
        </Button>
        <Button
          className="marginPointOne fifteenWidth buttonLoveBlue numbersButton"
          onClick={() => handleFilterSelecttion(ptEnablesUsers)}
        >
          PT-User <strong> {ptEnablesUsers?.length}</strong>
        </Button>
      </Row>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message>{error?.data?.error}</Message>
      ) : (
        <div>
          {usersToList ? (
            <Table striped bordered hover responsive="sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Id</th>
                  <th>Gender</th>
                  <th>Subscription</th>
                  <th>Expired On</th>
                  <th>Notes</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {usersToList.map((user) => (
                  <tr key={user.userId}>
                    <td>
                      <Link to={`/users/${user.userId}`}>{user.firstName}</Link>
                    </td>
                    <td>{user.userId}</td>
                    <td> {user.gender}</td>
                    <td> {user.subscriptionType}</td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {user.subscriptionExpiryDate
                        ? user.subscriptionExpiryDate?.split("T")[0]
                        : ""}
                    </td>
                    <td>
                      {editEnabled === user._id ? (
                        <Form.Control
                          value={notes}
                          type="text"
                          onChange={(e) => setNotes(e.target.value)}
                        ></Form.Control>
                      ) : (
                        <label>{user.notes}</label>
                      )}
                    </td>
                    <td>
                      {editEnabled === user._id ? (
                        <>
                          <button onClick={hanbdleSubmit}>
                            <CheckLg />
                          </button>
                          <button
                            onClick={hanbdleCancelEdit}
                            style={{
                              borderRadius: "10%",
                              margin: "8%",
                            }}
                          >
                            <XLg />
                          </button>
                        </>
                      ) : (
                        <button
                          style={{ borderRadius: "20%" }}
                          onClick={() => {
                            setEditEnabled(user._id);
                            setNotes(user.notes);
                          }}
                        >
                          <Pencil />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Message>No users Available</Message>
          )}
        </div>
      )}
    </>
  );
};

export default SubsExpiredUsersScreen;
