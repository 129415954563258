import { apiSlice } from "./apiSlice";
import { GYM_PAYMENT as GYM_PAYMENT_URL } from "../constants";

const gymPaymentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPayments: builder.query({
      query: (body) => ({
        url: `${GYM_PAYMENT_URL}${body.query ? body.query : ""}`,
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    updateGymPayment: builder.mutation({
      query: (data) => ({
        url: `${GYM_PAYMENT_URL}`,
        method: "POST",
        body: data.payload,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    updateGymPaymentByAdmin: builder.mutation({
      query: (data) => ({
        url: `${GYM_PAYMENT_URL}`,
        method: "POST",
        body: data.payload,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
  }),
});

export const {
  useGetPaymentsQuery,
  useUpdateGymPaymentMutation,
  useUpdateGymPaymentByAdminMutation,
} = gymPaymentApiSlice;
