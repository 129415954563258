const ContactUsScreen = () => {
  return (
    <div style={{ textAlign: "center", justifyContent: "center" }}>
      {" "}
      <h5>
        Incase of Any issues or query, Please contact us in the below email or
        mobile numbers
      </h5>
      <h2>
        Email : <label style={{ color: "blue" }}>contactus@zpotter.in</label>{" "}
        <br></br>
        <label style={{ color: "blue" }}>zpottertechnologies@gmail.com</label>
      </h2>
      <br></br>
      <h3 style={{ color: "green" }}>WhatsApp or Call</h3>{" "}
      <h2>
        Mobile 1 : 7806873973<br></br>
      </h2>
      <h2>Mobile 2 : 9092913756</h2>
    </div>
  );
};

export default ContactUsScreen;
