import { apiSlice } from "./apiSlice";
import { EXPENSES_URL } from "../constants";

const expenseApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createExpense: builder.mutation({
      query: (data) => ({
        url: `${EXPENSES_URL}`,
        method: "POST",
        body: data.payload,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    getExpenses: builder.query({
      query: (data) => ({
        url: `${EXPENSES_URL}?${data.query ? data.query : ""}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    updateExpense: builder.mutation({
      query: (data) => ({
        url: `${EXPENSES_URL}/${data._id}`,
        method: "PUT",
        body: data.payload,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
  }),
});

export const {
  useCreateExpenseMutation,
  useGetExpensesQuery,
  useUpdateExpenseMutation,
} = expenseApiSlice;
