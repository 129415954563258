import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: localStorage.getItem("users")
    ? JSON.parse(localStorage.getItem("users"))
    : [],
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
      localStorage.setItem("users", JSON.stringify(action.payload));
    },
    removeUsers: (state, action) => {
      //state.users = [];
      state.users = [];
      localStorage.removeItem("users");
    },
  },
});

export const { setUsers, removeUsers } = userSlice.actions;

export default userSlice.reducer;
