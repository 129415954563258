import jsPDF from "jspdf";
import "jspdf-autotable";

const generateInvoicePDF = async (customerDetails, invoiceDetails) => {
  const doc = new jsPDF();

  // Initial positions
  let startX = 10;
  let startY = 25;
  let lineHeight = 7; // Line height for text

  doc.setFontSize(15);
  doc.text("Invoice", startX + 75, startY);
  startY += lineHeight + 5;
  // Add a title
  doc.setFontSize(18);
  const gymName = doc.splitTextToSize(customerDetails?.gymName, 140); // Wrap text if it exceeds 120 units in width
  const gymLength = customerDetails?.gymName.length;
  doc.text(gymName, (startX + 190 - gymLength) / 3, startY);

  doc.setLineWidth(0.1);
  startY += 5;
  // Add customer details
  doc.setFontSize(16);
  doc.text(`To : `, startX + 4, startY + lineHeight);
  doc.setFontSize(12);
  startY += lineHeight;
  doc.text(
    `Member ID : ${customerDetails?.userId?.toString().substring(4)}`,
    startX + 10,
    startY + lineHeight
  );
  startY += lineHeight;
  doc.text(
    `Member Name : ${customerDetails?.userName}`,
    startX + 10,
    startY + lineHeight
  );
  startY += lineHeight;
  doc.text(
    `Mobile Number : ${customerDetails?.mobileNumber}`,
    startX + 10,
    startY + lineHeight
  );
  startY += lineHeight * 2; // Adding extra space before invoice details

  // Draw border around invoice details section
  // const invoiceDetailsHeight = 15;

  // Add invoice details
  const invoiceDate = invoiceDetails?.invoiceDate?.toString().split("T")[0];
  const invoiceNumber = `${customerDetails?.userId
    ?.toString()
    .substring(4)}${invoiceDate?.toString().substring(5).replace("-", "")}`;
  doc.text(`Invoice Date: ${invoiceDate}`, startX + 110, startY + lineHeight);
  startY += lineHeight;
  doc.text(
    `Invoice Number: ${invoiceNumber}`,
    startX + 110,
    startY + lineHeight
  );
  startY += lineHeight * 2; // Adding extra space before table

  // Add table with invoice items
  const tableColumn = [
    "S.No",
    "Description",
    "Start Date",
    "End Date",
    "Subscription Amount",
    "Paid Amount",
  ];
  const tableRows = [];

  const itemData = [
    invoiceDetails.sNo,
    invoiceDetails.description,
    invoiceDetails.startDate?.toString().split("T")[0],
    invoiceDetails.endDate?.toString().split("T")[0],
    invoiceDetails.subsAmount,
    invoiceDetails.paidAmount,
  ];
  tableRows.push(itemData);

  doc.autoTable(tableColumn, tableRows, { startY: startY });

  // Get the height of the autoTable
  const finalY = doc.autoTable.previous.finalY;

  // Add total amount
  doc.setFontSize(12);
  doc.text(
    `Total Paid Amount: ${invoiceDetails.paidAmount}`,
    startX + 4,
    finalY + 15
  );
  doc.text(
    `Pending Amount: ${invoiceDetails.pendingAmount}`,
    startX + 4,
    finalY + 21
  );

  doc.rect(10, 27, 190, finalY + 30); // x, y, width, height
  doc.setFontSize(8);
  doc.text(
    `"This is a computer-generated bill, signature is not required."`,
    startX + 50,
    finalY + 55
  );

  // Save or download the PDF
  doc.save(`invoice_${customerDetails.userName}_${invoiceNumber}.pdf`);
};

export default generateInvoicePDF;
