import { Button, Form, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Eye, EyeSlash } from "react-bootstrap-icons";

import { useChangeAssociatePasswordMutation } from "../slices/associatesSlice";
import RegistrationContainer from "../components/RegistrationContainer";

const ChangeAssociatePasswordScreen = ({
  associate,
  changeAssociatePassword,
}) => {
  console.log("associate");
  console.log(associate);
  const [associateId, setAssociateId] = useState(associate.associateId);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [pwdVisible, setPwdVisible] = useState(false);
  const [newPwdVisible, setNewPwdVisible] = useState(false);
  const [confirmPwdVisible, setConfirmPwdVisible] = useState(false);

  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);
  const [changeUserPassword, { isLoading }] =
    useChangeAssociatePasswordMutation();

  // let { search } = useLocation();
  // const ps = new URLSearchParams(search);
  // const redirect = ps.get("redirect") || "/";

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await changeUserPassword({
        payload: {
          password,
          associatePassword: newPassword,
          confirmAssociatePassword: confirmNewPassword,
          associateId,
        },
        token: userInfo.token,
      }).unwrap();
      navigate(`/associate/${associate.associateId}`);
      changeAssociatePassword();
      toast.success(
        "Associate Password Changed. Please ask the associate to login with new password"
      );
    } catch (e) {
      toast.error(e.data?.error);
    }
  };

  const handleCancelButton = () => {
    navigate(`/associate/${associate.associateId}`);
    changeAssociatePassword();
  };

  const setCurrentPwdVisibility = () => {
    setPwdVisible(!pwdVisible);
  };
  const setNewPwdVisibility = () => {
    setNewPwdVisible(!newPwdVisible);
  };
  const setConfirmPwdVisibility = () => {
    setConfirmPwdVisible(!confirmPwdVisible);
  };

  return (
    <>
      <RegistrationContainer>
        <h5> Change Password for {associate.firstName}</h5>
        <Form onSubmit={submitHandler}>
          <Row>
            {" "}
            <Form.Group as={Row} className="my-2" controlId="password">
              <Form.Label column="true" sm="4">
                Your Password :
              </Form.Label>
              <Col sm="5">
                <div style={{ display: "flex" }}>
                  <Form.Control
                    type={pwdVisible ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="enter current password"
                  ></Form.Control>{" "}
                  <Button onClick={setCurrentPwdVisibility}>
                    {pwdVisible ? <Eye /> : <EyeSlash />}
                  </Button>
                </div>
              </Col>{" "}
            </Form.Group>
            <Form.Group as={Row} className="my-2" controlId="newPassword">
              <Form.Label column="true" sm="4">
                Associate New Password :
              </Form.Label>{" "}
              <Col sm="5">
                {" "}
                <div style={{ display: "flex" }}>
                  <Form.Control
                    type={newPwdVisible ? "text" : "password"}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="enter New password"
                  ></Form.Control>
                  <Button onClick={setNewPwdVisibility}>
                    {newPwdVisible ? <Eye /> : <EyeSlash />}
                  </Button>{" "}
                </div>
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="my-2"
              controlId="confirmNewPassword"
            >
              <Form.Label column="true" sm="4">
                Confirm New Password :
              </Form.Label>
              <Col sm="5">
                <div style={{ display: "flex" }}>
                  <Form.Control
                    type={confirmPwdVisible ? "text" : "password"}
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    placeholder="enter New password"
                  ></Form.Control>{" "}
                  <Button onClick={setConfirmPwdVisibility}>
                    {confirmPwdVisible ? <Eye /> : <EyeSlash />}
                  </Button>{" "}
                </div>
              </Col>
            </Form.Group>
            <Row column="true" sm="6">
              <Col sm="4"></Col>
              <Col sm="5">
                <Button type="submit" disabled={isLoading} variant="primary">
                  {" "}
                  Submit
                </Button>{" "}
                <Button
                  style={{ marginLeft: "15%" }}
                  type="button"
                  disabled={isLoading}
                  variant="secondary"
                  onClick={handleCancelButton}
                >
                  {" "}
                  Cancel
                </Button>
              </Col>
            </Row>
          </Row>
        </Form>
      </RegistrationContainer>
    </>
  );
};

export default ChangeAssociatePasswordScreen;
