import { Button, Form, Row, Col, InputGroup } from "react-bootstrap";

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { useRegisterAssociateMutation } from "../slices/associatesSlice";
import { getCurrentDate } from "../utils/getCurrentDate";
import FieldWarning from "../components/FieldWarning";

const AssociateRegisterScreen = () => {
  // const [regCode, setRegCode] = useState();
  const { userInfo } = useSelector((state) => state.auth);
  const [associateId, setAssociateId] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("Male");
  const [workShift, setWorkShift] = useState(1);
  const [email, setEmail] = useState("");
  const [contactNumber, setConatctNumber] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [aadharNumber, setAadharNumber] = useState();
  const [panNumber, setPanNumber] = useState();
  const [associateRole, setAssociateRole] = useState("Associate");
  const [dateOfBirth, setDateOfBirth] = useState(getCurrentDate());
  const [joinedDate, setJoinedDate] = useState(getCurrentDate());

  const [associateIdPresent, setAssociateIdPresent] = useState(false);
  const [firstNamePresent, setFirstNamePresent] = useState(false);
  const [lastNamePresent, setLastNamePresent] = useState(false);
  const [passwordPresent, setPasswordPresent] = useState(false);
  const [confirmPasswordPresent, setConfirmPasswordPresent] = useState(false);
  const [genderPresent, setGenderPresent] = useState(false);
  const [mobileNumberPresent, setMobileNumberPresent] = useState(false);
  const [alternateNumberPresent, setAlternateNumberPresent] = useState(false);
  const [emailPresent, setEmailPresent] = useState(false);
  const [aadharNumberPresent, setAadharNumberPresent] = useState(false);
  const [panNumberPresent, setPanNumberPresent] = useState(false);
  const [joinedDatePresent, setJoinedDatePresent] = useState(false);
  const [dateOfBirthPresent, setDateOfBirthPresent] = useState(false);
  const [addressPresent, setAddressPresent] = useState(false);
  const [pincodePresent, setPincodePresent] = useState(false);

  const availableRoles = ["Rookie", "Associate", "Admin", "Super Admin"];
  const workShifts = [1, 2, 3, 4];
  const availableGender = ["Male", "Female", "Other"];
  const roleIds = { "Super Admin": 1, Admin: 2, Associate: 3, Rookie: 4 };

  const [pwdVisible, setPwdVisible] = useState(false);
  const [confirmPwdVisible, setConfirmPwdVisible] = useState(false);

  const [registerAssociate, { isLoading }] = useRegisterAssociateMutation();

  // const userInfo = useSelector((state) => state.auth);

  // let { search } = useLocation();
  // const ps = new URLSearchParams(search);
  // const redirect = ps.get("redirect") || "/";

  //   useEffect(() => {
  //     if (userInfo) {
  //       navigate(redirect);
  //     }
  //   }, [userInfo, redirect, navigate]);

  useEffect(() => {
    if (associateId?.length === 4 || associateId > 999) {
      setAssociateIdPresent(false);
    }

    if (firstName?.length > 0) {
      setFirstNamePresent(false);
    }
    if (lastName?.length > 0) {
      setLastNamePresent(false);
    }

    if (password?.length >= 8) {
      setPasswordPresent(false);
    }
    if (password === confirmPassword) {
      setConfirmPasswordPresent(false);
    }
    if (gender?.length > 1) {
      setGenderPresent(false);
    }

    if (contactNumber?.length === 10 && contactNumber > 1) {
      setMobileNumberPresent(false);
    }

    if (
      (alternateNumber?.length === 10 && alternateNumber > 1) ||
      alternateNumber?.length === 0
    ) {
      setAlternateNumberPresent(false);
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email) || email?.length === 0) {
      setEmailPresent(false);
    }

    if (aadharNumber?.length === 12 || aadharNumber?.length === 0) {
      setAadharNumberPresent(false);
    }

    if (panNumber?.length > 9 || panNumber?.length === 0) {
      setPanNumberPresent(false);
    }

    if (address?.length > 10 || address?.length === 0) {
      setAddressPresent(false);
    }

    if (pincode?.length === 6 || pincode?.length === 0) {
      setPincodePresent(false);
    }

    if (dateOfBirth < getCurrentDate()) {
      setDateOfBirthPresent(false);
    }

    if (joinedDate <= getCurrentDate()) {
      setJoinedDatePresent(false);
    }
  }, [
    firstName,
    lastName,
    gender,
    email,
    aadharNumber,
    dateOfBirth,
    joinedDate,
    address,
    associateId,
    contactNumber,
    password,
    confirmPassword,
    pincode,
    alternateNumber,
    panNumber,
  ]);

  const verifyInputFeilds = async () => {
    let fieldValidationSuccess = true;

    if (associateId?.length !== 4 || associateId > 9999 || associateId < 1) {
      setAssociateIdPresent(true);
      fieldValidationSuccess = false;
    }

    if (firstName?.length < 1 || firstName?.length > 100) {
      setFirstNamePresent(true);
      fieldValidationSuccess = false;
    }
    if (lastName?.length > 100) {
      setLastNamePresent(true);
      fieldValidationSuccess = false;
    }
    if (password?.length < 8 || password?.length > 50) {
      setPasswordPresent(true);
      fieldValidationSuccess = false;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordPresent(true);
      fieldValidationSuccess = false;
    }

    if (gender?.length < 1) {
      setGenderPresent(true);
      fieldValidationSuccess = false;
    }

    if (contactNumber?.length !== 10 || contactNumber < 1) {
      setMobileNumberPresent(true);
      fieldValidationSuccess = false;
    }
    if (alternateNumber)
      if (alternateNumber?.length !== 10 || alternateNumber < 1) {
        setAlternateNumberPresent(true);
        fieldValidationSuccess = false;
      }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email)
      if (!emailRegex.test(email) || email?.length > 300) {
        setEmailPresent(true);
        fieldValidationSuccess = false;
      }
    if (aadharNumber)
      if (aadharNumber?.length !== 12 || Number(aadharNumber) < 0) {
        setAadharNumberPresent(true);
        fieldValidationSuccess = false;
      }
    if (panNumber)
      if (panNumber?.length < 8 || panNumber < 1) {
        setPanNumberPresent(true);
        fieldValidationSuccess = false;
      }

    if (address)
      if (address?.length < 10 || address?.length > 1000) {
        setAddressPresent(true);
        fieldValidationSuccess = false;
      }
    if (pincode)
      if (pincode?.length !== 6 || pincode < 1) {
        setPincodePresent(true);
        fieldValidationSuccess = false;
      }

    if (!dateOfBirth || dateOfBirth >= getCurrentDate()) {
      setDateOfBirthPresent(true);
      fieldValidationSuccess = false;
    }

    if (joinedDate > getCurrentDate()) {
      setJoinedDatePresent(true);
      fieldValidationSuccess = false;
    }
    if (!fieldValidationSuccess)
      toast.error(
        "Some of the fields have invalid inputs. Please provide valid details"
      );
    return fieldValidationSuccess;
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    // function wait(ms) {
    //   return new Promise((resolve) => setTimeout(resolve, ms));
    // }
    if (await verifyInputFeilds())
      if (password !== confirmPassword) {
        toast.error("passwords do not match...");
      } else {
        try {
          const resAssociate = await registerAssociate({
            associateId: associateId,
            gymId: userInfo.gymId._id,
            firstName,
            lastName,
            password,
            email,
            aadharNumber,
            panNumber,
            address,
            confirmPassword,
            role: associateRole,
            roleId: roleIds[associateRole],
            gender: gender,
            mobileNumber: contactNumber,
            alternateNumber,
            workShift,
            joinedDate: joinedDate,
            dateOfBirth: dateOfBirth,
            pincode,
            token: userInfo.token,
          }).unwrap();
          toast.success("Associate is created : " + resAssociate.associateId);
          setAssociateId("");
          setPassword("");
          setConfirmPassword("");
          setFirstName("");
          setLastName("");
          setGender("Male");
          setWorkShift("");
          setEmail("");
          setConatctNumber("");
          setAlternateNumber("");
          setAddress("");
          setPincode("");
          setAadharNumber("");
          setPanNumber("");
          setAssociateRole("Associate");
          setDateOfBirth(getCurrentDate());
          setJoinedDate(getCurrentDate());
          // navigate("/");
        } catch (e) {
          toast.error(e?.data?.error || e.data);
        }
      }
  };

  const setPwdVisibility = () => {
    setPwdVisible(!pwdVisible);
  };

  const setConfirmPwdVisibility = () => {
    setConfirmPwdVisible(!confirmPwdVisible);
  };

  return (
    <>
      {userInfo.gymId.subscriptionType !== "Premium" ? (
        <>
          <h5>Associate Registration</h5>
          <Form onSubmit={submitHandler}>
            {/* <Form.Group as={Row} className="my-2" controlId="resistrationCode">
          <Form.Label column="true" sm="3">
            Registration Code * :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="text"
              value={regCode}
              onChange={(e) => setRegCode(e.target.value)}
              placeholder="enter Registration code"
            ></Form.Control>
          </Col>
        </Form.Group> */}
            <Row>
              <Col md={6}>
                <Form.Group as={Row} className="my-2" controlId="associateId">
                  <Form.Label column="true" sm="3">
                    Associate Id <label style={{ color: "red" }}>*</label> :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      pattern="[0-9]*" // Allow only digits
                      value={associateId}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                        setAssociateId(value);
                      }}
                      placeholder="enter Associate Id"
                    />
                    {associateIdPresent ? (
                      <FieldWarning text="Please provide 4 digit associate ID" />
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="my-2" controlId="password">
                  <Form.Label column="true" sm="3">
                    Password <label style={{ color: "red" }}>*</label> :
                  </Form.Label>
                  <Col sm="6">
                    <div style={{ display: "flex" }}>
                      <InputGroup>
                        {" "}
                        <Form.Control
                          type={pwdVisible ? "text" : "password"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="enter password"
                        ></Form.Control>{" "}
                        <Button onClick={setPwdVisibility}>
                          {pwdVisible ? <Eye /> : <EyeSlash />}
                        </Button>{" "}
                      </InputGroup>
                    </div>
                    {passwordPresent ? (
                      <FieldWarning text="Password should be atleast 8 chars long" />
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="my-2"
                  controlId="confirmPassword"
                >
                  <Form.Label column="true" sm="3">
                    Confirm Password <label style={{ color: "red" }}>*</label> :
                  </Form.Label>
                  <Col sm="6">
                    <div style={{ display: "flex" }}>
                      <InputGroup>
                        {" "}
                        <Form.Control
                          value={confirmPassword}
                          type={confirmPwdVisible ? "text" : "password"}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          placeholder="confirm password"
                        ></Form.Control>{" "}
                        <Button onClick={setConfirmPwdVisibility}>
                          {confirmPwdVisible ? <Eye /> : <EyeSlash />}
                        </Button>
                      </InputGroup>
                    </div>
                    {confirmPasswordPresent ? (
                      <FieldWarning text="Password and Confirm Password are not matching" />
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="my-2" controlId="firstName">
                  <Form.Label column="true" sm="3">
                    First Name <label style={{ color: "red" }}>*</label> :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="enter first Name"
                    ></Form.Control>{" "}
                    {firstNamePresent ? (
                      <FieldWarning text="First name should have minimum 1 char and max 100 char" />
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="my-2" controlId="lastName">
                  <Form.Label column="true" sm="3">
                    Last Name :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="enter last Name"
                    ></Form.Control>
                    {lastNamePresent ? (
                      <FieldWarning text="Last name can not have more than 100 characters" />
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="my-2" controlId="gender">
                  <Form.Label column="true" sm="3">
                    Select Gender <label style={{ color: "red" }}>*</label> :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Select
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      placeholder="Select Gender"
                    >
                      {availableGender.map((e) => (
                        <option key={e} value={e}>
                          {e}
                        </option>
                      ))}
                    </Form.Select>
                    {genderPresent ? (
                      <FieldWarning text="Please select gender" />
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="my-2" controlId="associateRole">
                  <Form.Label column="true" sm="3">
                    Associate Role <label style={{ color: "red" }}>*</label> :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Select
                      value={associateRole}
                      onChange={(e) => setAssociateRole(e.target.value)}
                      placeholder="Select Associate Role"
                    >
                      {availableRoles.map((e) => (
                        <option key={e} value={e}>
                          {e}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="my-2" controlId="workShift">
                  <Form.Label column="true" sm="3">
                    Work Shift:
                  </Form.Label>
                  <Col sm="6">
                    <Form.Select
                      value={workShift}
                      onChange={(e) => setWorkShift(e.target.value)}
                      placeholder="Select work Shift "
                    >
                      {workShifts.map((e) => (
                        <option key={e} value={e}>
                          {e}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="my-2" controlId="contactNumber">
                  <Form.Label column="true" sm="3">
                    Contact Number <label style={{ color: "red" }}>*</label> :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      pattern="[0-9]*"
                      value={contactNumber}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                        setConatctNumber(value);
                      }}
                      placeholder="enter contact number"
                    ></Form.Control>
                    {mobileNumberPresent ? (
                      <FieldWarning text="Please provide 10 digit mobile number" />
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group as={Row} className="my-2" controlId="dateOfBirth">
                  <Form.Label column="true" sm="3">
                    Date Of Birth <label style={{ color: "red" }}>*</label>:
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="date"
                      value={dateOfBirth}
                      onChange={(e) => setDateOfBirth(e.target.value)}
                      placeholder="in format 'YYYY-MM-DD'"
                    ></Form.Control>{" "}
                    {dateOfBirthPresent ? (
                      <FieldWarning text="Please provide valid date of birth" />
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="my-2" controlId="joinedDate">
                  <Form.Label column="true" sm="3">
                    Joined Date <label style={{ color: "red" }}>*</label>:
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="date"
                      value={joinedDate}
                      onChange={(e) => setJoinedDate(e.target.value)}
                      placeholder="in format 'YYYY-MM-DD'"
                    ></Form.Control>
                    {joinedDatePresent ? (
                      <FieldWarning text="Joined date can not be future date" />
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="my-2" controlId="email">
                  <Form.Label column="true" sm="3">
                    Email :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="enter gym or owner's email"
                    ></Form.Control>
                    {emailPresent ? (
                      <FieldWarning text="Please provide valid e-mail" />
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="my-2"
                  controlId="alternateNumber"
                >
                  <Form.Label column="true" sm="3">
                    Alternate Contact :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      pattern="[0-9]*" // Allow only digits
                      value={alternateNumber}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                        setAlternateNumber(value);
                      }}
                      placeholder="enter secondary Contact Numbers"
                    />
                    {alternateNumberPresent ? (
                      <FieldWarning text="Please provide 10 digit mobile Number" />
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="my-2" controlId="address">
                  <Form.Label column="true" sm="3">
                    Address :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      placeholder="enter Gym Address"
                    ></Form.Control>
                    {addressPresent ? (
                      <FieldWarning text="Address should be atleast 10 chars long" />
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="my-2" controlId="pincode">
                  <Form.Label column="true" sm="3">
                    Pincode :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      pattern="[0-9]*" // Allow only digits
                      value={pincode}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                        setPincode(value);
                      }}
                      placeholder="enter gym pincode"
                    />
                    {pincodePresent ? (
                      <FieldWarning text="Please provide valid 6 digit Pincode" />
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="my-2" controlId="panNumber">
                  <Form.Label column="true" sm="3">
                    PAN Number :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      value={panNumber}
                      onChange={(e) => setPanNumber(e.target.value)}
                      placeholder="enter PAN Number"
                    ></Form.Control>
                    {panNumberPresent ? (
                      <FieldWarning text="Please provide valid PAN number" />
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="my-2" controlId="aadharNumber">
                  <Form.Label column="true" sm="3">
                    Aadhar Number :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      pattern="[0-9]*" // Allow only digits
                      value={aadharNumber}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                        setAadharNumber(value);
                      }}
                      placeholder="enter Aadhar number"
                    />
                    {aadharNumberPresent ? (
                      <FieldWarning text="Please provide 12 digit Aadhar number" />
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
              </Col>
              <Row column="true" sm="6">
                <Col sm="4"></Col>
                <Col sm="5">
                  <Button type="submit" disabled={isLoading} variant="primary">
                    {" "}
                    Register
                  </Button>
                </Col>
              </Row>
            </Row>
          </Form>
        </>
      ) : (
        <div style={{ textAlign: "center", justifyContent: "center" }}>
          <label style={{ color: "red" }}>
            This feature is available only for Premium Plus.
          </label>
          <br></br>
          <br></br>
          <h5>Why take all the burdens on yourself ? </h5>
          <h6>
            {" "}
            Please upgrade to Premium Plus to create Associate profiles and let
            them Manage your gym for you.
          </h6>
        </div>
      )}
    </>
  );
};

export default AssociateRegisterScreen;
