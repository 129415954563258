import React, { useEffect, useState } from "react";
import { Row, Col, ListGroup, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetAttendanceQuery } from "../slices/attendanceSlice";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useGetUsersQuery } from "../slices/usersSlice";

const InActiveUsersScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);

  const today = new Date();
  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(today.getDate() - 30);

  const [startDate, setStartDate] = useState(
    thirtyDaysAgo?.toISOString().split("T")[0]
  );

  const [getApiQuery, setGetApiQuery] = useState(
    `?gymId=${userInfo.gymId._id}&attendanceDate[gte]=${startDate}`
  );

  const {
    data: attendances,
    isLoading,
    error,
    refetch,
  } = useGetAttendanceQuery({
    query: getApiQuery,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  let { data: users, isLoadingUsers } = useGetUsersQuery({
    payLoad: userInfo.gymId._id,
    token: userInfo.token,
  });

  // const usersIds = !users ? [] : users.map((user) => user?._id);
  const usersIdsInAttendace = isLoading
    ? []
    : attendances.map((att) => att.userId._id);

  let notActiveUsers;
  if (attendances && users) {
    notActiveUsers = users.filter((user) => {
      if (!usersIdsInAttendace.includes(user._id)) return user;
      else return null;
    });
  }

  const [usersToList, setUsersToList] = useState(
    isLoading && isLoadingUsers ? [] : attendances && users ? attendances : []
  );

  const [todaysUsers, setTodaysUsers] = useState(
    isLoading ? [] : attendances ? attendances.length : 0
  );
  const [maleUsers, setMaleUsers] = useState("");
  const [femaleUsers, setfemaleUsers] = useState("");
  const [seniorCitizen, setSeniorCitizen] = useState("");
  const [ptEnabledUsers, setPtEnabledUsers] = useState("");
  const [currentUsersAtGym, setCurrentUsersAtGym] = useState(0);

  const [todaysUniqueUsers, setTodaysUniqueUsers] = useState(
    isLoading ? [] : attendances ? attendances.length : 0
  );
  const [maleUniqueUsers, setMaleUniqueUsers] = useState("");
  const [femaleUniqueUsers, setfemaleUniqueUsers] = useState("");
  const [seniorUniqueCitizen, setSeniorUniqueCitizen] = useState("");
  const [ptEnabledUniqueUsers, setPtEnableUniqueUsers] = useState("");

  const handleGetUsers = async () => {
    setGetApiQuery(
      `?gymId=${userInfo.gymId._id}&attendanceDate[gte]=${startDate}`
    );
  };

  const [initApiRequestAtIntervals, setInitApiRequestAtIntervals] = useState(1);
  setInterval(() => {
    setInitApiRequestAtIntervals(Date.now());
  }, 1200000);

  useEffect(() => {
    refetch();
  }, [refetch, initApiRequestAtIntervals]);

  const getUniqueUsers = (users) => {
    let uniqueUsersIds = [];
    let uniqueUsers = [];
    users.map((user) => {
      if (!uniqueUsersIds.includes(user.userId._id)) {
        uniqueUsersIds.push(user.userId._id);
        uniqueUsers.push(user);
      }
      return null;
    });
    return uniqueUsers;
  };

  useEffect(() => {
    const todaysUsers = isLoading ? [] : attendances ? attendances : [];
    setTodaysUsers(todaysUsers);
    setUsersToList(todaysUsers);
    setTodaysUniqueUsers(getUniqueUsers(todaysUsers));
    const currentUsers = isLoading
      ? []
      : attendances
      ? attendances.filter((att) => {
          return att.timeIn >= Date.now() - 3600000 && !att.timeOut;
        })
      : [];
    setCurrentUsersAtGym(currentUsers);
    const maleUsers = isLoading
      ? []
      : attendances
      ? attendances.filter((att) => att.userId["gender"] === "Male")
      : [];
    setMaleUsers(maleUsers);
    setMaleUniqueUsers(getUniqueUsers(maleUsers));
    const femaleUsers = isLoading
      ? []
      : attendances
      ? attendances.filter((att) => att.userId["gender"] === "Female")
      : [];
    setfemaleUsers(femaleUsers);
    setfemaleUniqueUsers(getUniqueUsers(femaleUsers));
    const seniorUsers = isLoading
      ? []
      : attendances
      ? attendances.filter(
          (att) =>
            50 <
            Math.floor(
              (new Date() - new Date(att.userId["dateOfBirth"])) /
                (365.25 * 24 * 60 * 60 * 1000)
            )
        )
      : [];
    setSeniorCitizen(seniorUsers);
    setSeniorUniqueCitizen(getUniqueUsers(seniorUsers));
    const ptEnabledUsers = isLoading
      ? []
      : attendances
      ? attendances.filter((att) => att.userId["ptEnabled"])
      : [];
    setPtEnabledUsers(ptEnabledUsers);
    setPtEnableUniqueUsers(getUniqueUsers(ptEnabledUsers));
  }, [setTodaysUsers, attendances, isLoading]);

  const handleFilterSelecttion = (val) => {
    setUsersToList(val);
  };
  const handleUniqueUsersSelection = (val) => {
    setUsersToList(val);
  };

  return (
    <>
      <h3 style={{ color: "red" }}>THIS PAGE IS NOT IMPLEMENTED </h3>
      <div
        style={{
          margin: "1% 0% 3% 0%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <input
          placeholder="2023-11-23"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        ></input>{" "}
        <button onClick={handleGetUsers}>Get Data</button>
      </div>
      <Row className="dataRow">
        <Button
          className="marginPointEight sixteenWidth buttonGreen"
          onClick={() => handleFilterSelecttion(currentUsersAtGym)}
        >
          People at Gym :<strong> {currentUsersAtGym?.length}</strong>
        </Button>
        <Button
          className="marginPointOne sixteenWidth buttonPeach"
          onClick={() => handleFilterSelecttion(todaysUsers)}
        >
          Total Footfall :<strong> {todaysUsers?.length}</strong>
        </Button>
        <Button
          className="marginPointOne sixteenWidth buttonTeal"
          onClick={() => handleFilterSelecttion(maleUsers)}
        >
          Male :<strong> {maleUsers?.length}</strong>
        </Button>
        <Button
          className="marginPointOne sixteenWidth buttonMaroon"
          onClick={() => handleFilterSelecttion(femaleUsers)}
        >
          Female :<strong> {femaleUsers?.length}</strong>
        </Button>
        <Button
          className="marginPointOne sixteenWidth buttonOlive"
          onClick={() => handleFilterSelecttion(seniorCitizen)}
        >
          Seniors :<strong> {seniorCitizen?.length}</strong>
        </Button>
        <Button
          className="marginPointOne sixteenWidth buttonLoveBlue"
          onClick={() => handleFilterSelecttion(ptEnabledUsers)}
        >
          PT-Enabled :<strong> {ptEnabledUsers?.length}</strong>
        </Button>
      </Row>
      <Row className="dataRow">
        <Button className="marginPointEight sixteenWidth buttonGreen">
          <strong> Unique Users -{">"} </strong>
        </Button>
        <Button
          className="marginPointOne sixteenWidth buttonPeach"
          onClick={() => handleUniqueUsersSelection(todaysUniqueUsers)}
        >
          Total Users :<strong> {todaysUniqueUsers?.length}</strong>
        </Button>
        <Button
          className="marginPointOne sixteenWidth buttonTeal"
          onClick={() => handleUniqueUsersSelection(maleUniqueUsers)}
        >
          Male :<strong> {maleUniqueUsers?.length}</strong>
        </Button>
        <Button
          className="marginPointOne sixteenWidth buttonMaroon"
          onClick={() => handleUniqueUsersSelection(femaleUniqueUsers)}
        >
          Female :<strong> {femaleUniqueUsers?.length}</strong>
        </Button>
        <Button
          className="marginPointOne sixteenWidth buttonOlive"
          onClick={() => handleUniqueUsersSelection(seniorUniqueCitizen)}
        >
          Seniors :<strong> {seniorUniqueCitizen?.length}</strong>
        </Button>
        <Button
          className="marginPointOne sixteenWidth buttonLoveBlue"
          onClick={() => handleUniqueUsersSelection(ptEnabledUniqueUsers)}
        >
          PT-Enabled :<strong> {ptEnabledUniqueUsers?.length}</strong>
        </Button>
      </Row>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message>{error?.data?.error}</Message>
      ) : (
        <Row>
          <Row>
            {" "}
            <Col>
              <h5>Name</h5>
            </Col>
            <Col>
              <h5>User ID</h5>
            </Col>
            <Col>
              <h5>Date</h5>
            </Col>
            <Col>
              <h5>Time IN</h5>
            </Col>
            <Col>
              <h5>Time OUT</h5>
            </Col>
          </Row>
          {usersToList ? (
            <ListGroup>
              {usersToList.map((user) => (
                <ListGroup.Item>
                  <Row>
                    <Col>
                      <Link to={`/users/${user.userId._id}`}>
                        {user.firstName}{" "}
                      </Link>
                    </Col>
                    <Col>{user.userId._id}</Col>
                    <Col> {user.attendanceDate?.split("T")[0]}</Col>
                    <Col>
                      {" "}
                      {new Date(user.timeIn).getHours() +
                        ":" +
                        new Date(user.timeIn).getMinutes()}
                    </Col>
                    <Col>
                      {" "}
                      {user.timeOut
                        ? new Date(user.timeOut).getHours() +
                          ":" +
                          new Date(user.timeOut).getMinutes()
                        : "yet to mark Out-Time"}
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <h6>No users Available</h6>
          )}
        </Row>
      )}
    </>
  );
};

export default InActiveUsersScreen;
