import { Button, Form, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import RegistrationContainer from "../components/RegistrationContainer";
import { getCurrentDate } from "../utils/getCurrentDate";
import { useCreateUsersSelfMutation } from "../slices/usersSelfSlice";
import FieldWarning from "../components/FieldWarning";

const UserSelfRegistrationScreen = () => {
  const [regSuccess, setRegSuccess] = useState(false);
  const [userId, setUserId] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [heigthInCm, setHeigthInCm] = useState();
  const [weightInKg, setWeightInKg] = useState();
  const [joinedDate, setJoinedDate] = useState(getCurrentDate());
  const [dateOfBirth, setDateOfBirth] = useState();
  const [subscriptionType, setSubscriptionType] = useState("");
  const [gymGoal, setGymGoal] = useState("Muscle Gain and Strength Building");
  const [address, setAddress] = useState("");

  const [firstNamePresent, setFirstNamePresent] = useState(false);
  const [lastNamePresent, setLastNamePresent] = useState(false);
  const [genderPresent, setGenderPresent] = useState(false);
  const [mobileNumberPresent, setMobileNumberPresent] = useState(false);
  const [emailPresent, setEmailPresent] = useState("");
  const [aadharNumberPresent, setAadharNumberPresent] = useState(false);
  const [heigthInCmPresent, setHeigthInCmPresent] = useState(false);
  const [weightInKgPresent, setWeightInKgPresent] = useState(false);
  const [joinedDatePresent, setJoinedDatePresent] = useState(false);
  const [dateOfBirthPresent, setDateOfBirthPresent] = useState(false);
  // const [lastPaidDatePresent, setLastPaidDatePresent] = useState(false);
  // const [subscriptionAmountPresent, setSubscriptionAmountPresent] =
  //   useState(false);
  // const [lastPaidAmountPresent, setLastPaidAmountPresent] = useState(false);
  // const [paidAmountGreaterThanSubsAmount, setPaidAmountGreaterThanSubsAmount] =
  //   useState(false);
  const [subscriptionTypePresent, setSubscriptionTypePresent] = useState(false);
  const [gymGoalPresent, setGymGoalPresent] = useState(false);
  const [addressPresent, setAddressPresent] = useState(false);
  // const [txnIdPresent, setTxnIdPresent] = useState(false);

  const { gymUid: gymIdInUrl } = useParams();
  // const [gymUid, setEncodedUrl] = useState(gymIdInUrl);
  let encodeVal = Date.now().toString();
  const startVal = encodeVal?.substring(0, 5);
  const endVal = encodeVal?.substring(5);
  const redirectUrl = false;

  const navigate = useNavigate();
  useEffect(() => {
    // alert(`${startVal}${gymIdInUrl}${endVal}`);
    navigate(`/userSelfRegistration/${startVal}${gymIdInUrl}${endVal}`);
  }, [redirectUrl, startVal, navigate]);

  const genderList = ["Male", "Female", "Other"];
  const subscriptionList = [
    "Select Months",
    "1 Month",
    "3 Months",
    "6 Months",
    "12 Months",
  ];
  const gymGoalList = [
    "Select Gym Goal",
    "Weight Loss and Fat Reduction",
    "Muscle Gain and Strength Building",
    "Body Building",
    "Weight Lifting",
    "To Improve Stamina",
    "Stay Fit",
    "Health and Wellness",
    "Sports Performance",
    "Injury Rehabilitation",
  ];

  // const { userInfo } = useSelector((state) => state.auth);
  const [createUser, { isLoading }] = useCreateUsersSelfMutation();

  useEffect(() => {
    if (firstName?.length > 0) {
      setFirstNamePresent(false);
    }
    if (lastName?.length > 0) {
      setLastNamePresent(false);
    }

    if (gender?.length > 1) {
      setGenderPresent(false);
    }

    if (mobileNumber?.length === 10 && mobileNumber > 0) {
      setMobileNumberPresent(false);
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email)) {
      setEmailPresent(false);
    }

    if (aadharNumber?.length === 12) {
      setAadharNumberPresent(false);
    }

    if (Number(heigthInCm) >= 80 && Number(heigthInCm) < 230) {
      setHeigthInCmPresent(false);
    }
    if (Number(weightInKg) >= 20 && Number(weightInKg) < 200) {
      setWeightInKgPresent(false);
    }

    if (
      subscriptionType !== undefined &&
      subscriptionType !== "Select Months"
    ) {
      setSubscriptionTypePresent(false);
    }

    if (gymGoal !== "Select Gym Goal") {
      setGymGoalPresent(false);
    }
    if (address?.length > 0) {
      setAddressPresent(false);
    }

    if (dateOfBirth < getCurrentDate()) {
      setDateOfBirthPresent(false);
    }

    if (joinedDate <= getCurrentDate()) {
      setJoinedDatePresent(false);
    }
  }, [
    firstName,
    lastName,
    gender,
    mobileNumber,
    email,
    aadharNumber,
    heigthInCm,
    weightInKg,
    dateOfBirth,
    joinedDate,
    subscriptionType,
    gymGoal,
    address,
  ]);

  const verifyInputFeilds = async () => {
    let fieldValidationSuccess = true;

    if (firstName?.length < 1 || firstName?.length > 100) {
      setFirstNamePresent(true);
      fieldValidationSuccess = false;
    }
    if (lastName?.length > 100) {
      setLastNamePresent(true);
      fieldValidationSuccess = false;
    }

    if (gender?.length < 1) {
      setGenderPresent(true);
      fieldValidationSuccess = false;
    }

    if (mobileNumber?.length !== 10 || mobileNumber < 0) {
      setMobileNumberPresent(true);
      fieldValidationSuccess = false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email)
      if (!emailRegex.test(email) || email?.length > 300) {
        setEmailPresent(true);
        fieldValidationSuccess = false;
      }

    if (aadharNumber)
      if (aadharNumber?.length !== 12 || Number(aadharNumber) < 0) {
        setAadharNumberPresent(true);
        fieldValidationSuccess = false;
      }

    if (Number(heigthInCm) < 80 || Number(heigthInCm) > 230) {
      setHeigthInCmPresent(true);
      fieldValidationSuccess = false;
    }
    if (Number(weightInKg) < 20 || Number(weightInKg) > 200) {
      setWeightInKgPresent(true);
      fieldValidationSuccess = false;
    }

    if (subscriptionType === "Select Months" || !subscriptionType) {
      setSubscriptionTypePresent(true);
      fieldValidationSuccess = false;
    }

    if (gymGoal === "Select Gym Goal") {
      setGymGoalPresent(true);
      fieldValidationSuccess = false;
    }
    if (address)
      if (address?.length < 10 || address?.length > 1000) {
        setAddressPresent(true);
        fieldValidationSuccess = false;
      }

    if (dateOfBirth >= getCurrentDate() || !dateOfBirth) {
      setDateOfBirthPresent(true);
      fieldValidationSuccess = false;
    }

    if (joinedDate > getCurrentDate()) {
      setJoinedDatePresent(true);
      fieldValidationSuccess = false;
    }
    if (!fieldValidationSuccess)
      toast.error(
        "Some of the fields have invalid inputs. Please provide valid details"
      );
    return fieldValidationSuccess;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (await verifyInputFeilds())
      try {
        const res = await createUser({
          gymUid: gymIdInUrl,
          payLoad: {
            firstName,
            lastName,
            gender,
            mobileNumber,
            email,
            aadharNumber,
            heigthInCm,
            weightInKg,

            joinedDate,
            dateOfBirth,

            renewedDate: joinedDate,

            subscriptionType,
            gymGoal,
            address,
          },
        }).unwrap();
        toast.success(() => (
          <div>
            "User added Successfully"
            <br />
          </div>
        ));

        //  dispatch(setCredentials({ ...res }));
        // navigate(redirect);
        setUserId(res.userId);
        setRegSuccess(true);
        setFirstName("");
        setLastName("");
        setGender("");
        setMobileNumber("");
        setEmail("");
        setAadharNumber("");
        setHeigthInCm("");
        setWeightInKg("");

        setDateOfBirth();

        setSubscriptionType("");
        setGymGoal("");
        setAddress("");
      } catch (e) {
        toast.error(
          "Please provide " +
            e?.data?.message
              .replace("Path", "")
              .replace(".0", "")
              .split(":")[1]
              .split(",")[0]
        );
      }
  };

  return (
    <RegistrationContainer>
      {regSuccess ? (
        <h1 style={{ textAlign: "center" }}>
          Registration is successfull, Please inform the Gym associate
          <br />
          <br />
          Reference Id : {userId}
        </h1>
      ) : (
        <Form onSubmit={submitHandler}>
          {/* <Form.Group as={Row} className="my-2" controlId="userId">
          <Form.Label column sm="3">
            User ID * :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="text"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              placeholder="enter unique user Id"
            ></Form.Control>
          </Col>
        </Form.Group> */}
          <Form.Group as={Row} className="my-2" controlId="firtsName">
            <Form.Label column sm="3">
              First Name <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="enter First Name"
              ></Form.Control>
              {firstNamePresent ? (
                <FieldWarning text="First name should have minimum 1 char and max 100 char" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="lastName">
            <Form.Label column sm="3">
              Last Name :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="enter Last name"
              ></Form.Control>{" "}
              {lastNamePresent ? (
                <FieldWarning text="Last name can not have more than 100 characters" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="gender">
            <Form.Label column sm="3">
              Select Gender <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <div className="horizontal-radio-group">
                {genderList.map((option) => (
                  <Form.Check
                    key={option}
                    type="radio"
                    label={option}
                    name="genderRadio"
                    id={`genderRadio-${option}`}
                    value={option}
                    checked={gender === option}
                    onChange={(e) => setGender(e.target.value)}
                  />
                ))}
              </div>{" "}
              {genderPresent ? (
                <FieldWarning text="Please select gender" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="mobileNumber">
            <Form.Label column sm="3">
              Mobile Number <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                value={mobileNumber}
                type="number"
                onChange={(e) => setMobileNumber(e.target.value)}
                placeholder="enter mobile number"
              ></Form.Control>
              {mobileNumberPresent ? (
                <FieldWarning text="Please provide 10 digit Mobile number" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="my-2" controlId="dateOfBirth">
            <Form.Label column sm="3">
              Date Of Birth <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="date"
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
                placeholder="in format 'YYYY-MM-DD'"
              ></Form.Control>
              {dateOfBirthPresent ? (
                <FieldWarning text="Please provide valid date of birth" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="my-2" controlId="subscriptionType">
            <Form.Label column sm="3">
              Subscription Type <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <Form.Select
                value={subscriptionType}
                onChange={(e) => setSubscriptionType(e.target.value)}
                placeholder="Select subscription Type"
              >
                {subscriptionList.map((e) => (
                  <option value={e}>{e}</option>
                ))}
              </Form.Select>
              {subscriptionTypePresent ? (
                <FieldWarning text="Please select subscription type" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="gymGoal">
            <Form.Label column sm="3">
              Gym Goal <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <Form.Select
                value={gymGoal}
                onChange={(e) => setGymGoal(e.target.value)}
                placeholder="Select Gym Goal"
              >
                {gymGoalList.map((e) => (
                  <option value={e}>{e}</option>
                ))}
              </Form.Select>{" "}
              {gymGoalPresent ? (
                <FieldWarning text="Please select gym goal" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="my-2" controlId="joinedDate">
            <Form.Label column sm="3">
              Joined Date :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="date"
                value={joinedDate}
                onChange={(e) => setJoinedDate(e.target.value)}
                placeholder="in format 'YYYY-MM-DD'"
              ></Form.Control>
              {joinedDatePresent ? (
                <FieldWarning text="Joined date can not be future date" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="email">
            <Form.Label column sm="3">
              Email :
            </Form.Label>
            <Col sm="6">
              {" "}
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="enter email"
              ></Form.Control>
              {emailPresent ? (
                <FieldWarning text="Please provide valid e-mail" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="aadharNumber">
            <Form.Label column sm="3">
              Aadhar Number :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="number"
                value={aadharNumber}
                onChange={(e) => setAadharNumber(e.target.value)}
                placeholder="enter aadhar number"
              ></Form.Control>{" "}
              {aadharNumberPresent ? (
                <FieldWarning text="Please provide 12 digit Aadhar number" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="heigthInCm">
            <Form.Label column sm="3">
              Heigth In Cm :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="number"
                value={heigthInCm}
                onChange={(e) => setHeigthInCm(e.target.value)}
                placeholder="enter heigth in centimeter"
              ></Form.Control>{" "}
              {heigthInCmPresent ? (
                <FieldWarning text="Height should be between 80 cm and 230 cm" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="weightInKg">
            <Form.Label column sm="3">
              Weight In Kg :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="number"
                value={weightInKg}
                onChange={(e) => setWeightInKg(e.target.value)}
                placeholder="enter weight in Kg"
              ></Form.Control>
              {weightInKgPresent ? (
                <FieldWarning text="Weight should be between 20 kg and 200 kg" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="my-2" controlId="address">
            <Form.Label column sm="3">
              Address :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="enter Address"
              ></Form.Control>{" "}
              {addressPresent ? (
                <FieldWarning text="Address should be atleast 10 chars long" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Row column sm="6">
            <Col sm="4"></Col>
            <Col sm="5">
              <Button type="submit" disabled={isLoading} variant="primary">
                {" "}
                Register
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </RegistrationContainer>
  );
};

export default UserSelfRegistrationScreen;
