import React, { useEffect, useState } from "react";
import { Row, Col, ListGroup, Card, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import PaymentScreen from "./PaymentScreen";
import {
  useDeleteAssociateMutation,
  useGetAssociateByIdQuery,
} from "../slices/associatesSlice";
import AssociateEditScreen from "./AssociateEditScreen";
import { toast } from "react-toastify";
import AssociateChangeRoleScreen from "./AssociateChangeRoleScreen";
import ChangeAssociatePasswordScreen from "./ChangeAssociatePasswordScreen";
import VerticallyCenteredModal from "../components/SubmitModal";

const AssociateProfileScreen = () => {
  const { id: userId } = useParams();
  const { userInfo } = useSelector((state) => state.auth);
  const [modalShow, setModalShow] = useState(false);

  const navigate = useNavigate();
  const handleBackButton = () => {
    navigate("/associates");
  };

  const {
    data: user,
    isLoading,
    refetch,
  } = useGetAssociateByIdQuery({ params: userId, token: userInfo.token });

  const [deleteAssociate] = useDeleteAssociateMutation();

  const [editable, setEditable] = useState(false);
  const [payment, setPayment] = useState(false);
  const [changeRole, setChangeRole] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  const handleEditStatus = () => {
    setEditable(false);
  };

  const handlePaymentStatus = () => {
    setPayment(false);
  };

  const handleChangeRole = () => {
    setChangeRole(false);
  };

  const handleChangePassword = () => {
    setChangePassword(false);
  };

  const handleDelete = async () => {
    try {
      await deleteAssociate({
        associateId: userId,
        token: userInfo.token,
      }).unwrap();
      toast.success(`Associate ${userId} is removed `);
      navigate("/associates");
    } catch (e) {
      toast.error(e?.data?.error || e.data);
    }
  };

  useEffect(() => {
    refetch();
  }, [refetch, editable, payment, changeRole, changePassword]);

  const handleDeleteAssociate = () => {
    setModalShow(true);
  };

  return (
    <>
      <VerticallyCenteredModal
        title="Are you sure want to delete this Associate ?"
        size="md"
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSubmit={handleDelete}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {editable ? (
            <AssociateEditScreen
              user={user}
              editStatus={handleEditStatus}
            ></AssociateEditScreen>
          ) : payment ? (
            <PaymentScreen
              user={user}
              paymentStatus={handlePaymentStatus}
            ></PaymentScreen>
          ) : changeRole ? (
            <AssociateChangeRoleScreen
              user={user}
              changeRole={handleChangeRole}
            ></AssociateChangeRoleScreen>
          ) : changePassword ? (
            <ChangeAssociatePasswordScreen
              associate={user}
              changeAssociatePassword={handleChangePassword}
            ></ChangeAssociatePasswordScreen>
          ) : (
            <>
              <Button
                style={{ backgroundColor: "grey" }}
                onClick={handleBackButton}
              >
                Back
              </Button>{" "}
              <h1>
                Associate : {user?.firstName} {user?.lastName}
              </h1>
              {userInfo.roleId === 1 ? (
                <Row
                  style={{
                    margin: "0.5%",
                    justifyContent: "space-between",
                    width: "95%",
                  }}
                >
                  <Button
                    className=" marginPointOne sixteenWidth buttonTeal numbersButtonAssociateProfile"
                    onClick={(e) => setEditable(true)}
                  >
                    Edit
                  </Button>

                  <Button
                    className=" marginPointOne sixteenWidth buttonLoveBlue numbersButtonAssociateProfile"
                    onClick={(e) => setChangeRole(true)}
                  >
                    Change
                    <br /> Role
                  </Button>
                  <Button
                    className=" marginPointOne sixteenWidth buttonMaroon numbersButtonAssociateProfile"
                    onClick={(e) => setChangePassword(true)}
                  >
                    Change <br />
                    Password
                  </Button>
                  <Button
                    className=" marginPointOne sixteenWidth buttonReddish numbersButtonAssociateProfile"
                    onClick={handleDeleteAssociate}
                  >
                    Delete <br />
                    Associate
                  </Button>
                </Row>
              ) : (
                <></>
              )}
              <Row>
                <Col md={5}>
                  <Card>
                    <ListGroup>
                      {/* <ListGroup.Item>
                        <Row>
                          <Col> Name : </Col>
                          <Col> {user.firstName}</Col>
                        </Row>
                      </ListGroup.Item> */}
                      <ListGroup.Item>
                        <Row>
                          <Col> ID : </Col>
                          <Col> {user.associateId}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> Gender : </Col>
                          <Col> {user.gender}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> Role : </Col>
                          <Col> {user.role}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> Joined Date : </Col>
                          <Col> {user.joinedDate?.split("T")[0]}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> Date Of Birth : </Col>
                          <Col> {user.dateOfBirth?.split("T")[0]}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> Mobile Number : </Col>
                          <Col> {user.mobileNumber}</Col>
                        </Row>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Col>
                <Col md={5}>
                  <Card>
                    <ListGroup>
                      <ListGroup.Item>
                        <Row>
                          <Col> Alternate Number : </Col>
                          <Col> {user.alternateNumber}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> Work Shift : </Col>
                          <Col> {user.workShift}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> PAN Number : </Col>
                          <Col> {user.panNumber}</Col>
                        </Row>
                      </ListGroup.Item>{" "}
                      <ListGroup.Item>
                        <Row>
                          <Col> Aadhar Number : </Col>
                          <Col> {user.aadharNumber}</Col>
                        </Row>
                      </ListGroup.Item>{" "}
                      <ListGroup.Item>
                        <Row>
                          <Col> Email : </Col>
                          <Col> {user.email}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col> Address : </Col>
                            <Col> {user.address}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col> Pincode : </Col>
                            <Col> {user.pincode}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};

export default AssociateProfileScreen;
