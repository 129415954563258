import { apiSlice } from "./apiSlice";
import { GYMS_URL } from "../constants";

const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `${GYMS_URL}/login`,
        method: "POST",
        body: data,
      }),
    }),
    register: builder.mutation({
      query: (data) => ({
        url: `${GYMS_URL}`,
        body: data,
        method: "POST",
      }),
    }),
    updateGym: builder.mutation({
      query: (data) => ({
        url: `${GYMS_URL}/${data.gymId}`,
        method: "PUT",
        body: data.payload,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    updateMyGym: builder.mutation({
      query: (data) => ({
        url: `${GYMS_URL}/myGym`,
        method: "PUT",
        body: data.payload,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    updateUsersToken: builder.mutation({
      query: (data) => ({
        url: `${GYMS_URL}/tokenForUsers/${data.gymId}`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: `${GYMS_URL}/logout`,
        method: "POST",
      }),
    }),
    getMyGym: builder.query({
      query: (body) => ({
        url: `${GYMS_URL}/myGym`,
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useGetMyGymQuery,
  useUpdateGymMutation,
  useUpdateUsersTokenMutation,
  useUpdateMyGymMutation,
} = usersApiSlice;
