import { React, useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";

import Message from "../components/Message";
import { useGetAssociatesQuery } from "../slices/associatesSlice";

const AssociatesScreen = () => {
  const [searchBy, setSearchBy] = useState("Id");
  const [searchValue, setSearchValue] = useState("");
  const { userInfo } = useSelector((state) => state.auth);

  const searchOptions = {
    Id: "_id",
    Name: "firstName",
    Mobile: "mobileNumber",
  };
  let {
    data: users,
    isLoading,
    refetch,
    error,
  } = useGetAssociatesQuery({
    token: userInfo.token,
  });

  const [totalUsers, setTotalUsers] = useState(
    isLoading ? [] : users ? users.associates : []
  );

  const [usersToList, setUsersToList] = useState(
    isLoading ? [] : users ? users.associates : []
  );

  useEffect(() => {
    refetch();
    setTotalUsers(isLoading ? [] : users ? users.associates : []);
    setUsersToList(isLoading ? [] : users ? users.associates : []);
  }, [refetch, users]);

  // const handleSelect = (e) => {
  //   setSearchBy(e.target.value);
  // };
  // const onSearchTextChange = (val) => {
  //   setSearchValue(val);
  //   const searchedUsers = users.filter((user) => {
  //     let userData;
  //     if (searchBy === "Name") {
  //       userData = String(user[searchOptions[searchBy]]).toLowerCase();
  //     } else {
  //       userData = String(user[searchOptions[searchBy]]);
  //     }
  //     return userData.includes(searchValue.toLowerCase());
  //   });
  //   setUsersToList(searchedUsers);
  // };
  // const handleSearchButton = () => {
  //   const searchedUsers = users.filter((user) => {
  //     let userData;
  //     if (searchBy === "Name") {
  //       userData = String(user[searchOptions[searchBy]]).toLowerCase();
  //     } else {
  //       userData = String(user[searchOptions[searchBy]]);
  //     }
  //     return userData.includes(searchValue.toLowerCase());
  //   });
  //   setUsersToList(searchedUsers);
  // };

  const handleFilterSelecttion = (val) => {
    setUsersToList(val);
  };

  return (
    <>
      <h3>Associates List</h3>

      <Button
        style={{
          whiteSpace: "nowrap",
          marginBottom: "2%",
          backgroundColor: "#27aa67",
        }}
        onClick={() => handleFilterSelecttion(totalUsers)}
      >
        Total Associates :<strong> {totalUsers?.length}</strong>
      </Button>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message>{error?.data?.error}</Message>
      ) : (
        <Table striped bordered hover responsive="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Id</th>
              <th>Role</th>
              <th>Shift</th>
            </tr>
          </thead>
          {usersToList ? (
            <tbody>
              {usersToList.map((user) => (
                <tr>
                  <td>
                    <Link to={`/associate/${user.associateId}`}>
                      {user.firstName}
                    </Link>
                  </td>

                  <td> {user.associateId}</td>
                  <td> {user.role}</td>

                  <td> {user.workShift}</td>
                </tr>
              ))}
            </tbody>
          ) : (
            <Message>No users Available</Message>
          )}
        </Table>
      )}
    </>
  );
};

export default AssociatesScreen;
