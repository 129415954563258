import React from "react";

function FieldWarning({ text }) {
  return (
    <div>
      {" "}
      <p
        style={{
          fontWeight: "lighter",
          fontSize: "0.9rem",
          color: "red",
        }}
      >
        {text}
      </p>
    </div>
  );
}

export default FieldWarning;
