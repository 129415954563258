import { apiSlice } from "./apiSlice";
import { PAYPAL_URL } from "../constants";

const paymentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPayments: builder.query({
      query: (body) => ({
        url: `${PAYPAL_URL}${body.query ? body.query : ""}`,
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const { useGetPaymentsQuery } = paymentApiSlice;
