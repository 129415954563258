import { Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { XLg } from "react-bootstrap-icons";
import * as XLSX from "xlsx";

import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { useUploadUsersMutation } from "../slices/usersSlice";
import { getCurrentDate } from "../utils/getCurrentDate";
import { isValidEmail } from "../utils/isValidEmail";
import { useGetUsersQuery } from "../slices/usersSlice";

const UsersUploadScreen = () => {
  const [excelFile, setExcelFile] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const { userInfo } = useSelector((state) => state.auth);
  const [createUser, { isLoading }] = useUploadUsersMutation();
  let errorResponse = null;

  let {
    data: users,
    isLoading: isUsersLoading,
    refetch: usersRefetch,
    error: usersError,
  } = useGetUsersQuery({
    payLoad: userInfo.gymId._id,
    token: userInfo.token,
  });

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    const dotIndex = selectedFile.name.lastIndexOf(".");
    const fileExtension = selectedFile.name.slice(dotIndex + 1);
    if (
      fileExtension === "xlsx" ||
      fileExtension === "xls" ||
      fileExtension === "csv"
    ) {
      try {
        setExcelFile(selectedFile.name);
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, { type: "buffer" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws);
          setExcelData(data);
        };
      } catch (e) {
        toast.error("Please select valid excel file");
      }
    } else toast.error("Only files with extensions .xlsx or .xls are allowed");
  };

  const verifyUsersExist = async () => {
    let duplicateUsers = [];
    let existingUsers = isUsersLoading ? [] : users;

    for (let i = 0; i < excelData?.length; i++) {
      for (let j = 0; j < existingUsers?.length; j++) {
        if (excelData[i].userId === existingUsers[j].userId) {
          duplicateUsers.push(excelData[i].userId);
          break;
        }
      }
    }
    return duplicateUsers;
  };

  const verifyExcelData = async () => {
    let isDataValid = true;
    let errorObject = {};
    let uniqueExcelUsers = [];
    let duplicateUserPresent = false;

    excelData?.forEach((d) => {
      if (uniqueExcelUsers.includes(d.userId)) {
        duplicateUserPresent = true;
        errorObject.userId =
          "Excel contains duplicate userIds, Please correct it and upload again";
        isDataValid = false;
      } else {
        uniqueExcelUsers.push(d.userId);
      }
    });
    if (!duplicateUserPresent)
      excelData?.forEach((d) => {
        // console.log("userId");
        // console.log(typeof d.userId);
        // console.log("firstName");
        // console.log(typeof d.firstName);
        // console.log("ptEnabled");
        // console.log(typeof d.ptEnabled);
        // console.log("gender");
        // console.log(typeof d.gender);
        // console.log("subscriptionAmount");
        // console.log(typeof d.subscriptionAmount);
        // console.log("paidAmount");
        // console.log(typeof d.paidAmount);
        // console.log("mobileNumber");
        // console.log(typeof d.mobileNumber);
        // console.log("aadharNumber");
        // console.log(typeof d.aadharNumber);
        // console.log("joinedDate");
        // console.log(typeof d.joinedDate);
        // console.log("dateOfBirth");
        // console.log(typeof d.dateOfBirth);
        // console.log("lastPaidDate");
        // console.log(typeof d.lastPaidDate);
        // console.log("subscriptionType");
        // console.log(typeof d.subscriptionType);
        // console.log("email");
        // console.log(typeof d.email);
        // console.log("gymGoal");
        // console.log(typeof d.gymGoal);
        // console.log("address");
        // console.log(typeof d.address);
        // console.log("isActive");
        // console.log(typeof d.isActive);
        // console.log("renewedDate");
        // console.log(typeof d.renewedDate);
        // console.log("subscriptionExpiryDate");
        // console.log(typeof d.subscriptionExpiryDate);

        const regexDate = /^\d{4}-\d{2}-\d{2}$/;

        if (
          !d.userId ||
          typeof d.userId !== "number" ||
          d.userId?.toString().length < 4 ||
          d.userId?.toString().length > 7
        ) {
          // console.log(d.userId);
          errorObject.userId = "userId should be between 4 to 7 digits number";
          isDataValid = false;
        }
        if (
          !d.firstName ||
          d.firstName?.length < 1 ||
          d.firstName?.length > 150
        ) {
          // console.log(d.userId);
          // console.log(d.firstName);
          errorObject.firstName =
            "firstName should not be empty and it should not contain more than 200 characters";
          isDataValid = false;
        }

        if (
          !d.ptEnabled ||
          !(d.ptEnabled === "true" || d.ptEnabled === "false")
        ) {
          // console.log(d.userId);
          // console.log(d.ptEnabled);
          errorObject.ptEnabled = "ptEnabled should be either True or False";
          isDataValid = false;
        }
        if (
          !d.gender ||
          !(
            d.gender === "Male" ||
            d.gender === "Female" ||
            d.gender === "Other"
          )
        ) {
          // console.log(d.userId);
          // console.log(d.gender);
          errorObject.gender =
            "gender should not be other than Male, Female and Other";
          isDataValid = false;
        }
        if (
          !d.subscriptionAmount ||
          typeof d.subscriptionAmount !== "number" ||
          Number(d.subscriptionAmount) < 1 ||
          Number(d.subscriptionAmount) > 99999
        ) {
          // console.log(d.userId);
          // console.log(d.subscriptionAmount);
          errorObject.subscriptionAmount =
            "subscriptionAmount should be between 1 and 99999 Rs";
          isDataValid = false;
        }

        if (
          d.paidAmount === null ||
          typeof d.paidAmount !== "number" || // Check for null
          d.paidAmount === undefined ||
          Number(d.paidAmount) < 0 ||
          Number(d.paidAmount) > Number(d.subscriptionAmount)
        ) {
          // console.log(d.userId);
          // console.log(d.paidAmount);
          errorObject.paidAmount =
            "paidAmount should be atleast 0 and it should not exceed Subscription amount";

          isDataValid = false;
        }
        if (
          !d.mobileNumber ||
          typeof d.mobileNumber !== "number" ||
          d.mobileNumber?.toString().length !== 10 ||
          Number(d.mobileNumber) < 0
        ) {
          // console.log(d.userId);
          // console.log(d.mobileNumber);
          errorObject.mobileNumber = "mobileNumber should be 10 digit Number";
          isDataValid = false;
        }
        if (d.aadharNumber)
          if (
            d.aadharNumber?.toString().length !== 12 ||
            Number(d.aadharNumber) < 0
          ) {
            // console.log(d.userId);
            // console.log(d.aadharNumber);
            errorObject.aadharNumber =
              "aadharNumber be either empty or 12 digit Number";
            isDataValid = false;
          }

        if (
          !d.joinedDate ||
          d.joinedDate > getCurrentDate() ||
          !regexDate.test(d.joinedDate)
        ) {
          // console.log(d.userId);
          // console.log(d.joinedDate);
          errorObject.joinedDate =
            "joinedDate should not be empty and it should not be future date";
          isDataValid = false;
        }

        if (
          !d.dateOfBirth ||
          d.dateOfBirth >= getCurrentDate() ||
          !regexDate.test(d.dateOfBirth)
        ) {
          // console.log(d.userId);
          // console.log(d.dateOfBirth);
          errorObject.dateOfBirth =
            "dateOfBirth should not be empty and it should not be future date";
          isDataValid = false;
        }

        if (
          !d.lastPaidDate ||
          d.lastPaidDate > getCurrentDate() ||
          !regexDate.test(d.lastPaidDate)
        ) {
          // console.log(d.userId);
          // console.log(d.lastPaidDate);
          errorObject.lastPaidDate =
            "lastPaidDate should not be empty and it should not be future date";
          isDataValid = false;
        }
        if (
          !d.subscriptionType ||
          !(
            d.subscriptionType === "1 Month" ||
            d.subscriptionType === "3 Months" ||
            d.subscriptionType === "6 Months" ||
            d.subscriptionType === "12 Months"
          )
        ) {
          // console.log(d.userId);
          // console.log(d.subscriptionType);
          errorObject.subscriptionType =
            "subscriptionType should be one of '1 Month','3 Months','6 Months','12 Months'";
          isDataValid = false;
        }

        if (!(isValidEmail(d.email) || d.email?.length === 0)) {
          // console.log(d.userId);
          // console.log(d.email);
          errorObject.email = "email should be valid or empty";
          isDataValid = false;
        }

        if (
          !(
            d.gymGoal?.length === 0 ||
            d.gymGoal === "Weight Loss and Fat Reduction" ||
            d.gymGoal === "Muscle Gain and Strength Building" ||
            d.gymGoal === "Body Building" ||
            d.gymGoal === "Weight Lifting" ||
            d.gymGoal === "To Improve Stamina" ||
            d.gymGoal === "Stay Fit" ||
            d.gymGoal === "Health and Wellness" ||
            d.gymGoal === "Sports Performance" ||
            d.gymGoal === "Injury Rehabilitation"
          )
        ) {
          // console.log(d.userId);
          // console.log(d.gymGoal);
          errorObject.gymGoal =
            'gymGoal should be empty or one of "Weight Loss and Fat Reduction",  "Muscle Gain and Strength Building", "Body Building","Weight Lifting" , "To Improve Stamina" , "Stay Fit", "Health and Wellness" ,"Sports Performance" , "Injury Rehabilitation"';
          isDataValid = false;
        }

        if (!(d.isActive === "true" || d.isActive === "false")) {
          // console.log(d.userId);
          // console.log(d.isActive);
          errorObject.isActive = "isActive should be one of 'True' or 'False'";
          isDataValid = false;
        }

        if (
          d.renewedDate === undefined ||
          d.renewedDate > getCurrentDate() ||
          !regexDate.test(d.renewedDate)
        ) {
          errorObject.renewedDate =
            "renewedDate should not be empty and should not be future date";
          isDataValid = false;
        }

        const isValid = regexDate.test(d.subscriptionExpiryDate);
        if (
          d.subscriptionExpiryDate === null ||
          d.subscriptionExpiryDate === undefined ||
          !isValid
        ) {
          errorObject.subscriptionExpiryDate =
            "subscriptionExpiryDate should be a valid date";
          isDataValid = false;
        }
      });
    errorResponse = Object.values(errorObject).join(". \n");
    return isDataValid;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (excelFile === null) {
      toast.error("Please select excel file");
    } else {
      const duplicateUsers = await verifyUsersExist();
      if (!(await verifyExcelData())) {
        //  console.log(errorResponse);
        toast.error("Please address below errors : \n" + errorResponse);
      } else if (duplicateUsers?.length > 0) {
        toast.error(
          "Below user IDs are already available, Please remove these users from Excel or provide different IDs for these users \n : " +
            duplicateUsers.join(",")
        );
      } else {
        try {
          await createUser({
            token: userInfo.token,
            payload: excelData,
          }).unwrap();
          toast.success(() => <div>"Users Uploaded Successfully"</div>);
          setExcelFile(null);
          setExcelData(null);
        } catch (e) {
          toast.error(e?.data?.error + " : \n " + e.data?.users);
          setExcelFile(null);
          setExcelData(null);
        }
      }
    }
  };

  const clearFile = async () => {
    setExcelFile(null);
    document.getElementById("fileInput").value = "";
  };

  return (
    <>
      {" "}
      <h3>Upload Users</h3>
      <FormContainer>
        {isLoading ? (
          <Loader />
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group as={Row} className="my-2">
              <Form.Label>
                <strong>Select Excel file to upload</strong>
              </Form.Label>
              <InputGroup>
                {" "}
                <Form.Control
                  type="file"
                  onChange={handleFileChange}
                  id="fileInput"
                />
                {excelFile ? (
                  <button type="button" onClick={clearFile}>
                    <XLg />
                  </button>
                ) : (
                  ""
                )}
              </InputGroup>
            </Form.Group>

            <Row column="true" sm="6">
              <Col sm="4"></Col>
              <Col sm="5">
                <Button type="submit" disabled={isLoading} variant="primary">
                  {" "}
                  Upload
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default UsersUploadScreen;
