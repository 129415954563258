import { Spinner } from "react-bootstrap";

const Loader = () => {
  return (
    <>
      <h1
        style={{
          textAlign: "center",
        }}
      >
        Please wait...
      </h1>
      <Spinner
        animation="border"
        role="status"
        style={{
          width: "100px",
          height: "100px",
          margin: "auto",
          display: "block",
        }}
      ></Spinner>
    </>
  );
};

export default Loader;
