import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  attendances: localStorage.getItem("attendances")
    ? JSON.parse(localStorage.getItem("attendances"))
    : [],
};

const attendancesListSlice = createSlice({
  name: "attendances",
  initialState,
  reducers: {
    setAttendances: (state, action) => {
      state.attendances = action.payload;
      localStorage.setItem("attendances", JSON.stringify(action.payload));
    },
    removeAttendances: (state, action) => {
      state.attendances = [];
      localStorage.removeItem("attendances");
    },
  },
});

export const { setAttendances, removeAttendances } =
  attendancesListSlice.actions;

export default attendancesListSlice.reducer;
