import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  usersSelf: localStorage.getItem("usersSelf")
    ? JSON.parse(localStorage.getItem("usersSelf"))
    : [],
};

const userSelfSlice = createSlice({
  name: "usersSelf",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.usersSelf = action.payload;
      localStorage.setItem("usersSelf", JSON.stringify(action.payload));
    },
    removeSelfUsers: (state, action) => {
      //state.users = [];
      state.usersSelf = [];
      localStorage.removeItem("usersSelf");
    },
  },
});

export const { setUsers, removeSelfUsers } = userSelfSlice.actions;

export default userSelfSlice.reducer;
