import { apiSlice } from "./apiSlice";
import { USERSELF_URL } from "../constants";

const usersSelfApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsersSelf: builder.query({
      query: (body) => ({
        url: `${USERSELF_URL}${body.query ? body.query : ""}`,
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    getUserSelf: builder.query({
      query: (body) => ({
        url: `${USERSELF_URL}/${body.userId}`,
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    createUsersSelf: builder.mutation({
      query: (data) => ({
        url: `${USERSELF_URL}/${data.gymUid}`,
        method: "POST",
        body: data.payLoad,
      }),
    }),

    deleteUserSelf: builder.mutation({
      query: (body) => ({
        url: `${USERSELF_URL}/${body.userId}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }),
    }),
  }),
});

export const {
  useGetUsersSelfQuery,
  useGetUserSelfQuery,
  useCreateUsersSelfMutation,
  useDeleteUserSelfMutation,
} = usersSelfApiSlice;
