//import React from 'react'
import { Container } from "react-bootstrap";
import { Outlet } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "./components/Header";
import Footer from "./components/Footer";

import bgImage from "./assets/bgImage.png";

const App = () => {
  // ENABLE THIS CODE FOR AUTO LOGOFF
  // const { userInfo } = useSelector((state) => state.auth);
  // const dispatch = useDispatch();
  // setTimeout(() => {
  //   console.log("currTime - loginTime");
  //   console.log(
  //     new Date(getCurrentDate() + getCurrentTime()) -
  //       new Date(userInfo?.loginDate)
  //   );

  //   if (
  //     userInfo !== null &&
  //     (new Date(getCurrentDate() + getCurrentTime()) -
  //       new Date(userInfo?.loginDate)) /
  //       1000 >
  //       86400
  //   ) {
  //     console.log("clearing redux after 60 minutes");
  //     dispatch(logout());
  //   }
  // }, 60000);

  return (
    <>
      <ToastContainer position="top-center" autoClose="3000" />
      <Header />
      <main
        className="py-3"
        style={{
          position: "relative",
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover", // this will make the background cover the entire area
          backgroundPosition: "center", // this will center the image
          backgroundRepeat: "no-repeat", // this will prevent the image from repeating
          height: "100vh", // adjust the height as needed
          backgroundAttachment: "fixed",
          overflow: "scroll",
        }}
      >
        <Container>
          <Outlet />
        </Container>
      </main>
      <Footer />
    </>
  );
};

export default App;
