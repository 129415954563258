import { Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { useUpdateAttendanceMutation } from "../slices/attendanceSlice";
import { useNavigate, useParams } from "react-router-dom";

import welcomeMessage from "../assets/audios/messageTone.mp3";

const AttendanceScreenFromMobile = () => {
  const [userId, setUserId] = useState();
  const { gymUid: gymIdInUrl } = useParams();
  const navigate = useNavigate();
  // const [gymUid, setEncodedUrl] = useState(gymIdInUrl);
  let encodeVal = Date.now().toString();
  const startVal = encodeVal?.substring(0, 5);
  const endVal = encodeVal?.substring(5);
  const redirectUrl = false;
  // alert(`${startVal}${endVal}`);

  useEffect(() => {
    // alert(`${startVal}${gymIdInUrl}${endVal}`);
    navigate(`/attendanceFromMobile/${startVal}${gymIdInUrl}${endVal}`);
  }, [redirectUrl, navigate]);

  const [updateAttendance, { isLoading }] = useUpdateAttendanceMutation();

  const submitHandler = async (e) => {
    e.preventDefault();
    // if (!userInfo) toast.error("Please login to use the application");
    if (userId < 1 || userId > 999999 || userId.startsWith("0"))
      toast.error("Please enter valid user Id");
    else {
      try {
        const res = await updateAttendance({
          payLoad: {
            userId,
          },
          params: gymIdInUrl,
        }).unwrap();
        // dispatch(setCredentials({ ...res }));
        // navigate(redirect);
        // navigate("/");
        if (res.subsAlreadyExpired) {
          toast.info(() => (
            <div>
              Subscription already expired for {res.userName} on{" "}
              {res.expiresOn?.split("T")[0]}
            </div>
          ));
        } else {
          toast.success(() => (
            <div>
              <audio src={welcomeMessage} type="audio/mp3" autoPlay></audio>
              {res.message} {res.userName}
              <br />
              Valid Till: {res.expiresOn?.split("T")[0]}
            </div>
          ));
        }
        setUserId("");
      } catch (err) {
        err?.status?.status === "PARSING_ERROR"
          ? toast.error("Please enter valid user Id")
          : toast.error(err.data.error);
      }
    }
  };

  return (
    <>
      <FormContainer>
        <Form onSubmit={submitHandler}>
          <Form.Group className="my-2" controlId="userId">
            <Form.Label id="userId">Enter Your ID</Form.Label>
            <Form.Control
              type="number"
              placeholder="enter Id"
              autoComplete="off"
              autoFocus
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            />
          </Form.Group>

          <Button type="submit" variant="primary">
            Submit
          </Button>
          {isLoading && <Loader />}
        </Form>
      </FormContainer>
    </>
  );
};

export default AttendanceScreenFromMobile;
