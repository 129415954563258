import { apiSlice } from "./apiSlice";
import { VISITORS_URL } from "../constants";

const visitorsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createVisitor: builder.mutation({
      query: (data) => ({
        url: `${VISITORS_URL}`,
        method: "POST",
        body: data.payload,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    getVisitors: builder.query({
      query: (data) => ({
        url: `${VISITORS_URL}?${data.query ? data.query : ""}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    updateVisitor: builder.mutation({
      query: (data) => ({
        url: `${VISITORS_URL}/${data._id}`,
        method: "PUT",
        body: data.payload,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    getVisitorsDashboard: builder.query({
      query: (data) => ({
        url: `${VISITORS_URL}/dashboard${data.query ? data.query : ""}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
  }),
});

export const {
  useCreateVisitorMutation,
  useGetVisitorsQuery,
  useUpdateVisitorMutation,
  useGetVisitorsDashboardQuery,
} = visitorsApiSlice;
