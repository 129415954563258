import { Button, Form, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import imageCompression from "browser-image-compression";

import {
  useCreateUsersMutation,
  useUploadPhotoMutation,
} from "../slices/usersSlice";
import { getCurrentDate } from "../utils/getCurrentDate";
import FieldWarning from "../components/FieldWarning";
import VerticallyCenteredModal from "../components/SubmitModal";
import Loader from "../components/Loader";

const UserRegistrationScreen = () => {
  const [userId, setUserId] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [aadharNumber, setAadharNumber] = useState();
  const [heigthInCm, setHeigthInCm] = useState();
  const [weightInKg, setWeightInKg] = useState();
  const [ptEnabled, setPtEnabled] = useState(false);
  const [joinedDate, setJoinedDate] = useState(getCurrentDate());
  const [dateOfBirth, setDateOfBirth] = useState();
  const [lastPaidDate, setLastPaidDate] = useState(getCurrentDate());
  const [subscriptionAmount, setSubscriptionAmount] = useState();
  const [lastPaidAmount, setLastPaidAmount] = useState();
  const [subscriptionType, setSubscriptionType] = useState();
  const [gymGoal, setGymGoal] = useState("Muscle Gain and Strength Building");
  const [address, setAddress] = useState("");
  const [txnId, setTxnId] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [file, setFile] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false);

  const [userIdStartWithZero, setUserIdStartWithZero] = useState(false);
  const [userIdTooLarge, setUserIdTooLarge] = useState(false);
  const [userIdPresent, setUserIdPresent] = useState(false);
  const [firstNamePresent, setFirstNamePresent] = useState(false);
  const [lastNamePresent, setLastNamePresent] = useState(false);
  const [genderPresent, setGenderPresent] = useState(false);
  const [mobileNumberPresent, setMobileNumberPresent] = useState(false);
  const [emailPresent, setEmailPresent] = useState("");
  const [aadharNumberPresent, setAadharNumberPresent] = useState(false);
  const [heigthInCmPresent, setHeigthInCmPresent] = useState(false);
  const [weightInKgPresent, setWeightInKgPresent] = useState(false);
  const [joinedDatePresent, setJoinedDatePresent] = useState(false);
  const [dateOfBirthPresent, setDateOfBirthPresent] = useState(false);
  const [lastPaidDatePresent, setLastPaidDatePresent] = useState(false);
  const [subscriptionAmountPresent, setSubscriptionAmountPresent] =
    useState(false);
  const [lastPaidAmountPresent, setLastPaidAmountPresent] = useState(false);
  const [paidAmountGreaterThanSubsAmount, setPaidAmountGreaterThanSubsAmount] =
    useState(false);
  const [subscriptionTypePresent, setSubscriptionTypePresent] = useState(false);
  const [gymGoalPresent, setGymGoalPresent] = useState(false);
  const [addressPresent, setAddressPresent] = useState(false);
  const [txnIdPresent, setTxnIdPresent] = useState(false);
  const [fileInvalidError, setFileInvalidError] = useState(false);

  const genderList = ["Male", "Female", "Other"];
  const subscriptionList = [
    "Select Months",
    "1 Month",
    "3 Months",
    "6 Months",
    "12 Months",
  ];
  const gymGoalList = [
    "Select Gym Goal",
    "Weight Loss and Fat Reduction",
    "Muscle Gain and Strength Building",
    "Body Building",
    "Weight Lifting",
    "To Improve Stamina",
    "Stay Fit",
    "Health and Wellness",
    "Sports Performance",
    "Injury Rehabilitation",
  ];

  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);
  const [createUser, { isLoading }] = useCreateUsersMutation();
  const [uploadPhoto, { isLoading: isPhotoUploading }] =
    useUploadPhotoMutation();

  // const userInfo = useSelector((state) => state.auth);

  // let { search } = useLocation();
  // const ps = new URLSearchParams(search);
  // const redirect = ps.get("redirect") || "/";

  //   useEffect(() => {
  //     if (userInfo) {
  //       navigate(redirect);
  //     }
  //   }, [userInfo, redirect, navigate]);

  useEffect(() => {
    if (userId?.toString().length > 0) {
      setUserIdPresent(false);
    }

    if (userId?.toString().length <= 7) {
      setUserIdTooLarge(false);
    }

    if (!userId?.startsWith("0")) {
      setUserIdStartWithZero(false);
    }

    if (firstName?.trim().length > 0) {
      setFirstNamePresent(false);
    }
    if (lastName?.trim().length > 0) {
      setLastNamePresent(false);
    }

    if (gender?.length > 1) {
      setGenderPresent(false);
    }

    if (mobileNumber?.length === 10 && mobileNumber > 0) {
      setMobileNumberPresent(false);
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email.trim()) || email?.trim().length === 0) {
      setEmailPresent(false);
    }

    if (aadharNumber?.length === 12 || aadharNumber?.length === 0) {
      setAadharNumberPresent(false);
    }

    if (
      (Number(heigthInCm) >= 80 && Number(heigthInCm) < 230) ||
      heigthInCm?.length === 0
    ) {
      setHeigthInCmPresent(false);
    }

    if (
      (Number(weightInKg) >= 20 && Number(weightInKg) < 200) ||
      weightInKg?.length === 0
    ) {
      setWeightInKgPresent(false);
    }

    if (Number(lastPaidAmount) >= 0 && Number(lastPaidAmount) < 500001) {
      setLastPaidAmountPresent(false);
    }

    if (Number(lastPaidAmount) < Number(subscriptionAmount)) {
      setPaidAmountGreaterThanSubsAmount(false);
    }

    if (Number(subscriptionAmount) > 0 && Number(subscriptionAmount) < 500001) {
      setSubscriptionAmountPresent(false);
    }

    if (txnId?.trim().length >= 0) {
      setTxnIdPresent(false);
    }

    if (
      subscriptionType !== undefined &&
      subscriptionType !== "Select Months"
    ) {
      setSubscriptionTypePresent(false);
    }

    if (gymGoal !== "Select Gym Goal") {
      setGymGoalPresent(false);
    }
    if (address?.trim().length > 10 || address?.length === 0) {
      setAddressPresent(false);
    }

    if (dateOfBirth < getCurrentDate()) {
      setDateOfBirthPresent(false);
    }
    if (lastPaidDate <= getCurrentDate()) {
      setLastPaidDatePresent(false);
    }

    if (joinedDate <= getCurrentDate()) {
      setJoinedDatePresent(false);
    }
  }, [
    userId,
    firstName,
    lastName,
    gender,
    mobileNumber,
    email,
    aadharNumber,
    heigthInCm,
    weightInKg,
    ptEnabled,
    dateOfBirth,
    lastPaidAmount,
    lastPaidDate,
    joinedDate,
    subscriptionAmount,
    txnId,
    subscriptionType,
    gymGoal,
    address,
  ]);

  const verifyInputFeilds = async () => {
    let fieldValidationSuccess = true;

    if (userId)
      if (userId?.length < 1) {
        setUserIdPresent(true);
        fieldValidationSuccess = false;
      }

    if (userId?.startsWith("0")) {
      setUserIdStartWithZero(true);
      fieldValidationSuccess = false;
    }

    if (userId?.length > 7) {
      setUserIdTooLarge(true);
      fieldValidationSuccess = false;
    }

    if (firstName?.trim().length < 1 || firstName?.trim().length > 100) {
      setFirstNamePresent(true);
      fieldValidationSuccess = false;
    }
    if (lastName?.trim().length > 100) {
      setLastNamePresent(true);
      fieldValidationSuccess = false;
    }

    if (Number(lastPaidAmount) > Number(subscriptionAmount)) {
      setPaidAmountGreaterThanSubsAmount(true);
      fieldValidationSuccess = false;
    }

    if (gender?.length < 1) {
      setGenderPresent(true);
      fieldValidationSuccess = false;
    }

    if (mobileNumber?.length !== 10 || mobileNumber < 0) {
      setMobileNumberPresent(true);
      fieldValidationSuccess = false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email)
      if (!emailRegex.test(email.trim()) || email?.trim().length > 300) {
        setEmailPresent(true);
        fieldValidationSuccess = false;
      }

    if (aadharNumber)
      if (aadharNumber?.length !== 12 || Number(aadharNumber) < 0) {
        setAadharNumberPresent(true);
        fieldValidationSuccess = false;
      }
    if (heigthInCm)
      if (Number(heigthInCm) < 80 || Number(heigthInCm) > 230) {
        setHeigthInCmPresent(true);
        fieldValidationSuccess = false;
      }

    if (weightInKg)
      if (Number(weightInKg) < 20 || Number(weightInKg) > 200) {
        setWeightInKgPresent(true);
        fieldValidationSuccess = false;
      }

    if (
      !lastPaidAmount ||
      Number(lastPaidAmount) < 0 ||
      Number(lastPaidAmount) > 500001
    ) {
      setLastPaidAmountPresent(true);
      fieldValidationSuccess = false;
    }

    if (
      !subscriptionAmount ||
      Number(subscriptionAmount) <= 0 ||
      Number(subscriptionAmount) > 500001
    ) {
      setSubscriptionAmountPresent(true);
      fieldValidationSuccess = false;
    }

    if (txnId?.trim().length > 40) {
      setTxnIdPresent(true);
      fieldValidationSuccess = false;
    }
    if (subscriptionType === "Select Months" || !subscriptionType) {
      setSubscriptionTypePresent(true);
      fieldValidationSuccess = false;
    }

    if (gymGoal === "Select Gym Goal") {
      setGymGoalPresent(true);
      fieldValidationSuccess = false;
    }
    if (
      (address?.trim().length < 10 && address?.trim().length > 0) ||
      address?.trim().length > 1000
    ) {
      setAddressPresent(true);
      fieldValidationSuccess = false;
    }

    if (dateOfBirth >= getCurrentDate() || !dateOfBirth) {
      setDateOfBirthPresent(true);
      fieldValidationSuccess = false;
    }
    if (lastPaidDate > getCurrentDate()) {
      setLastPaidDatePresent(true);
      fieldValidationSuccess = false;
    }

    if (joinedDate > getCurrentDate()) {
      setJoinedDatePresent(true);
      fieldValidationSuccess = false;
    }
    if (!fieldValidationSuccess)
      toast.error(
        "Some of the fields have invalid inputs. Please provide valid details"
      );
    return fieldValidationSuccess;
  };

  const submitHandler = async (e) => {
    // e.preventDefault();

    try {
      const res = await createUser({
        payLoad: {
          gymId: userInfo.gymId._id,
          firstName: firstName.trim(),
          newUserId: userId,
          lastName: lastName?.trim(),
          gender,
          mobileNumber,
          email: email?.trim(),
          aadharNumber,
          heigthInCm,
          weightInKg,
          ptEnabled,
          joinedDate,
          dateOfBirth,
          paidAmount: lastPaidAmount,
          lastPaidDate,
          renewedDate: joinedDate,
          subscriptionAmount,
          lastPaidAmount,
          transactionId: txnId?.trim(),
          subscriptionType,
          gymGoal,
          address: address?.trim(),
        },
        token: userInfo.token,
      }).unwrap();
      let imageUploadRes;
      if (res && file) {
        let formData = new FormData();
        formData.append("image", file);
        formData.append("imageKey", `${userInfo.gymId?._id}${res.userId}`);
        imageUploadRes = await uploadPhoto({
          token: userInfo.token,
          payload: formData,
        }).unwrap();
      }
      if (res && imageUploadRes)
        toast.success(() => (
          <div>
            "User Registered and Photo uploaded Successfully"
            <br />
            <br />
            User Id : {res.userId}
          </div>
        ));

      if (res && !imageUploadRes)
        toast.success(() => (
          <div>
            "User Registered Successfully, but Photo is not Uploaded"
            <br />
            <br />
            User Id : {res.userId}
          </div>
        ));

      //  dispatch(setCredentials({ ...res }));
      // navigate(redirect);
      // if (res && file)
      //   await uploadPhoto({
      //     token: userInfo.token,
      //     payload: file,
      //   }).unwrap();
      setUserId("");
      setFirstName("");
      setLastName("");
      setGender("");
      setMobileNumber("");
      setEmail("");
      setAadharNumber("");
      setHeigthInCm("");
      setWeightInKg("");
      setTxnId("");
      setPtEnabled(false);
      setDateOfBirth("");
      setLastPaidAmount("");
      setSubscriptionAmount("");
      setSubscriptionType("");
      setGymGoal("");
      setAddress("");
      setFile(null);
      setFileInvalidError(false);
      setFileSizeError(false);
      navigate("/userRegistration");
    } catch (e) {
      e?.data?.message &&
        toast.error(
          "Please provide " +
            e?.data?.message
              .replace("Path", "")
              .replace(".0", "")
              .split(":")[1]
              .split(",")[0]
        );
      e?.data?.error && toast.error(e?.data?.error);
    }
  };

  const compressImage = async (selectedImage) => {
    const file = selectedImage;
    if (file) {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 280,
          useWebWorker: true,
          initialQuality: 1,
        };

        console.log("Un-Compressed file:", file);
        const compressedFile = await imageCompression(file, options);
        // setFile(compressedFile);
        console.log("first Compressed file:", compressedFile);
        const secondOptions = {
          maxSizeMB: 1, // maximum size in MB
          useWebWorker: true, // use web worker for performance
          initialQuality: 0.9, // initial quality setting
        };

        // Second compression step
        const secondCompressedFile = await imageCompression(
          compressedFile,
          secondOptions
        );
        console.log("Second Compressed file:", secondCompressedFile);
        setFile(secondCompressedFile);

        // // You can now upload the compressed file to your server
        // uploadImage(compressedFile);
      } catch (error) {
        console.error("Error processing image:", error);
      }
    }
  };

  const handleFileChange = async (e) => {
    const selectedFile = e?.target?.files[0];
    const dotIndex = selectedFile?.name?.lastIndexOf(".");
    const fileExtension = selectedFile?.name?.slice(dotIndex + 1).toLowerCase();
    let errorPresent = false;
    if (
      !(
        fileExtension === "jpeg" ||
        fileExtension === "jpg" ||
        fileExtension === "png" ||
        fileExtension === "tiff" ||
        fileExtension === "svg"
      )
    ) {
      errorPresent = true;
      setFileInvalidError(true);
      setFile(null);
    } else if (selectedFile?.size / 1000 > 5120) {
      setFileSizeError(true);
      setFile(null);
    } else if (!errorPresent && selectedFile?.size / 1000 <= 5120) {
      setFileSizeError(false);
      setFileInvalidError(false);
      // setFile(selectedFile);
      await compressImage(selectedFile);
    }
  };

  const handleSubmit = async () => {
    try {
      if (await verifyInputFeilds()) setModalShow(true);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <h5>User Registration</h5>{" "}
      <VerticallyCenteredModal
        title="Are you sure want to Register this user ?"
        size="sm"
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSubmit={submitHandler}
        // setEdit={() => paymentStatus(true)}
      />
      {isLoading || (isPhotoUploading && <Loader />)}
      <Row>
        <Col md={5}>
          <Form.Group as={Row} className="my-2" controlId="userId">
            <Form.Label column="true" sm="3">
              User ID :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={userId}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setUserId(value);
                }}
                placeholder="enter User ID"
              />
              {userIdPresent ? (
                <FieldWarning text="User ID must be atleast 1 digit long." />
              ) : (
                ""
              )}
              {userIdStartWithZero ? (
                <FieldWarning text="User ID should not start with 0." />
              ) : (
                ""
              )}
              {userIdTooLarge ? (
                <FieldWarning text="User ID must be less than 7 digits" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="firtsName">
            <Form.Label column="true" sm="3">
              First Name <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="enter First Name"
              ></Form.Control>{" "}
              {firstNamePresent ? (
                <FieldWarning text="First name should have minimum 1 char and max 100 char" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="lastName">
            <Form.Label column="true" sm="3">
              Last Name :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="enter Last name"
              ></Form.Control>{" "}
              {lastNamePresent ? (
                <FieldWarning text="Last name can not have more than 100 characters" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="gender">
            <Form.Label column="true" sm="3">
              Select Gender <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <div className="horizontal-radio-group">
                {genderList.map((option) => (
                  <Form.Check
                    key={option}
                    type="radio"
                    label={option}
                    name="genderRadio"
                    id={`genderRadio-${option}`}
                    value={option}
                    checked={gender === option}
                    onChange={(e) => setGender(e.target.value)}
                  />
                ))}
              </div>{" "}
              {genderPresent ? (
                <FieldWarning text="Please select gender" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="mobileNumber">
            <Form.Label column="true" sm="3">
              Mobile Number <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={mobileNumber}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setMobileNumber(value);
                }}
                placeholder="enter mobile number"
              />
              {mobileNumberPresent ? (
                <FieldWarning text="Please provide 10 digit Mobile number" />
              ) : (
                ""
              )}
            </Col>{" "}
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="dateOfBirth">
            <Form.Label column="true" sm="3">
              Date Of Birth <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="date"
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
                placeholder="in format 'YYYY-MM-DD'"
              ></Form.Control>
              {dateOfBirthPresent ? (
                <FieldWarning text="Please provide valid date of birth" />
              ) : (
                ""
              )}
            </Col>{" "}
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="subscriptionType">
            <Form.Label column="true" sm="3">
              Subscription Type <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <Form.Select
                value={subscriptionType}
                onChange={(e) => setSubscriptionType(e.target.value)}
                placeholder="Select subscription Type"
              >
                {subscriptionList.map((e) => (
                  <option key={e} value={e}>
                    {e}
                  </option>
                ))}
              </Form.Select>{" "}
              {subscriptionTypePresent ? (
                <FieldWarning text="Please select subscription type" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="gymGoal">
            <Form.Label column="true" sm="3">
              Gym Goal <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <Form.Select
                value={gymGoal}
                onChange={(e) => setGymGoal(e.target.value)}
                placeholder="Select Gym Goal"
              >
                {gymGoalList.map((e) => (
                  <option key={e} value={e}>
                    {e}
                  </option>
                ))}
              </Form.Select>{" "}
              {gymGoalPresent ? (
                <FieldWarning text="Please select gym goal" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="subscriptionAmount">
            <Form.Label column="true" sm="3">
              Subscription Amount <label style={{ color: "red" }}>*</label> :
            </Form.Label>{" "}
            <Col sm="6">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={subscriptionAmount}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setSubscriptionAmount(value);
                }}
                placeholder="enter subscription Amount"
              />
              {subscriptionAmountPresent ? (
                <FieldWarning text="Subscription amount should be between 1 and 500000 Rs" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>{" "}
          <Form.Group as={Row} className="my-2" controlId="lastPaidAmount">
            <Form.Label column="true" sm="3">
              Paid Amount <label style={{ color: "red" }}>*</label>::
            </Form.Label>{" "}
            <Col sm="6">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={lastPaidAmount}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setLastPaidAmount(value);
                }}
                placeholder="Enter recently Paid Amount"
              />
              {lastPaidAmountPresent ? (
                <FieldWarning text="Please provide paid amount" />
              ) : (
                ""
              )}
              {paidAmountGreaterThanSubsAmount ? (
                <FieldWarning text="Paid amount can not be greater than Subscription amount" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
        </Col>
        <Col md={5}>
          <Form.Group as={Row} className="my-2" controlId="trxId">
            <Form.Label column="true" sm="3">
              Transaction Id :
            </Form.Label>{" "}
            <Col sm="6">
              <Form.Control
                type="text"
                value={txnId}
                onChange={(e) => setTxnId(e.target.value)}
                placeholder="enter transaction Id"
              ></Form.Control>
              {txnIdPresent ? (
                <FieldWarning text="Please provide valid transaction id " />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="lastPaidDate">
            <Form.Label column="true" sm="3">
              Paid Date :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="date"
                value={lastPaidDate}
                onChange={(e) => setLastPaidDate(e.target.value)}
                placeholder="in format 'YYYY-MM-DD'"
              ></Form.Control>
              {lastPaidDatePresent ? (
                <FieldWarning text="Paid date can not be future date" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="ptEnabled">
            <Form.Label column="true" sm="3">
              Personal Trainer Availed ?{" "}
            </Form.Label>
            <Col sm="6">
              <div className="horizontal-radio-group">
                {[true, false].map((option) => (
                  <div key={option}>
                    <Form.Check
                      key={option}
                      type="radio"
                      label={option === true ? "YES" : "NO"}
                      name="ptEnabled"
                      id={`ptEnabled-${option}`}
                      value={option}
                      checked={ptEnabled === option}
                      onChange={(e) => setPtEnabled(option)}
                    />
                  </div>
                ))}
              </div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="joinedDate">
            <Form.Label column="true" sm="3">
              Joined Date :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="date"
                value={joinedDate}
                onChange={(e) => setJoinedDate(e.target.value)}
                placeholder="in format 'YYYY-MM-DD'"
              ></Form.Control>
              {joinedDatePresent ? (
                <FieldWarning text="Joined date can not be future date" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="email">
            <Form.Label column="true" sm="3">
              Email :
            </Form.Label>
            <Col sm="6">
              {" "}
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="enter email"
              ></Form.Control>
              {emailPresent ? (
                <FieldWarning text="Please provide valid e-mail" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="aadharNumber">
            <Form.Label column="true" sm="3">
              Aadhar Number :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={aadharNumber}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setAadharNumber(value);
                }}
                placeholder="enter aadhar number"
              />
              {aadharNumberPresent ? (
                <FieldWarning text="Please provide 12 digit Aadhar number" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="heigthInCm">
            <Form.Label column="true" sm="3">
              Heigth In Cm :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={heigthInCm}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setHeigthInCm(value);
                }}
                placeholder="enter heigth in centimeter"
              />
              {heigthInCmPresent ? (
                <FieldWarning text="Height should be between 80 cm and 230 cm" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="weightInKg">
            <Form.Label column="true" sm="3">
              Weight In Kg :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={weightInKg}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setWeightInKg(value);
                }}
                placeholder="enter weight in Kg"
              />
              {weightInKgPresent ? (
                <FieldWarning text="Weight should be between 20 kg and 200 kg" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="my-2" controlId="address">
            <Form.Label column="true" sm="3">
              Address :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="enter Address"
              ></Form.Control>
              {addressPresent ? (
                <FieldWarning text="Address should be atleast 10 chars long" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="photo">
            <Form.Label column="true" sm="3">
              Upload Photo :
            </Form.Label>
            <Col sm="6">
              <input type="file" onChange={handleFileChange} />
              {fileSizeError && (
                <label style={{ color: "red" }}>
                  Image size should be less than 5 MB, Please select different
                  Image.
                </label>
              )}
              {fileInvalidError && (
                <label style={{ color: "red" }}>
                  Only jpeg, jpg, png, tiff and svg formats are supported
                </label>
              )}
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row column="true" sm="6">
        <Col sm="4"></Col>
        <Col sm="5">
          <Button
            type="submit"
            disabled={isLoading}
            variant="primary"
            onClick={handleSubmit}
          >
            {" "}
            Register
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default UserRegistrationScreen;
