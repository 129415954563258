import React from "react";
import { Bar } from "react-chartjs-2";

const HorizontalBarChart = ({ chartData }) => {
  const screenWidth = window.innerWidth;
  return (
    <div className="barChart">
      <p style={{ color: "red" }}>{chartData.labelText?.split("...")[1]}</p>
      <Bar
        height={screenWidth < 500 && "300px"}
        data={chartData}
        options={{
          indexAxis: "y",
          // maintainAspectRatio: false, // Allow the chart to resize
          plugins: {
            title: {
              display: true,
              text: "Top Attendees",
            },
            legend: {
              display: false,
              position: "bottom",
            },
          },
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
              position: "left",
            },
          },
        }}
      />
      <label>{chartData.labelText?.split("...")[0]}</label>
    </div>
  );
};

export default HorizontalBarChart;
