import { React, useEffect, useState } from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../components/Loader";
import { setUsers } from "../slices/userSelfSlice";
import Message from "../components/Message";
import { useGetUsersSelfQuery } from "../slices/usersSelfSlice";
import UserSelfProfileScreen from "./UserSelfProfileScreen";

const PendingRegsScreen = () => {
  const [searchBy, setSearchBy] = useState("Id");
  const [approveEnabled, setApproveEnabled] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { userInfo } = useSelector((state) => state.auth);
  const [userSelf, setUserSelf] = useState();

  const handleReview = (val) => {
    setApproveEnabled(true);
    setUserSelf(val);
  };

  const filterOptions = ["Id", "Name", "Mobile"];

  const dispatch = useDispatch();

  const searchOptions = {
    Id: "_id",
    Name: "firstName",
    Mobile: "mobileNumber",
  };
  let {
    data: users,
    isLoading,
    refetch,
    error,
  } = useGetUsersSelfQuery({
    token: userInfo.token,
  });

  const [totalUsers, setTotalUsers] = useState(
    isLoading ? [] : users ? users : []
  );
  const [usersToList, setUsersToList] = useState(
    isLoading ? [] : users ? users : []
  );
  // const [activeUsers, setActiveUsers] = useState("");
  // const [inActiveUsers, setInactiveUsers] = useState("");
  const [maleUsers, setMaleUsers] = useState("");
  const [femaleUsers, setfemaleUsers] = useState("");
  const [seniorCitizens, setSeniorCitizens] = useState("");
  const [ptEnablesUsers, setPtEnablesUsers] = useState("");

  useEffect(() => {
    refetch();
    if (users) dispatch(setUsers(users));
    setTotalUsers(isLoading ? [] : users ? users : 0);
    setUsersToList(isLoading ? [] : users ? users : 0);

    setMaleUsers(
      isLoading
        ? []
        : users
        ? users.filter((user) => user["gender"] === "Male")
        : []
    );

    setfemaleUsers(
      isLoading
        ? []
        : users
        ? users.filter((user) => user["gender"] === "Female")
        : []
    );
    setSeniorCitizens(
      isLoading
        ? []
        : users
        ? users.filter(
            (user) =>
              50 <
              Math.floor(
                (new Date() - new Date(user["dateOfBirth"])) /
                  (365.25 * 24 * 60 * 60 * 1000)
              )
          )
        : []
    );

    setPtEnablesUsers(
      isLoading ? [] : users ? users.filter((user) => user["ptEnabled"]) : []
    );
  }, [refetch, dispatch, users, approveEnabled]);

  const handleSelect = (e) => {
    setSearchBy(e.target.value);
  };
  const onSearchTextChange = (val) => {
    setSearchValue(val);
    const searchedUsers = users?.filter((user) => {
      let userData;
      if (searchBy === "Name") {
        userData = String(user[searchOptions[searchBy]]).toLowerCase();
      } else {
        userData = String(user[searchOptions[searchBy]]);
      }
      return userData.includes(val.toLowerCase());
    });
    setUsersToList(searchedUsers);
  };

  const handleFilterSelecttion = (val) => {
    setUsersToList(val);
  };

  return (
    <>
      <h5>Pending Registrations</h5>
      {approveEnabled ? (
        <UserSelfProfileScreen
          user={userSelf}
          setApproveEnabled={setApproveEnabled}
        />
      ) : (
        <>
          <Row
            className="mb-3"
            style={{
              justifyContent: "center",
              padding: "auto",
              margin: "auto",
              width: "95%",
              alignItems: "center",
            }}
          >
            <Col>
              <Form.Group as={Row} className="my-2" controlId="filterBy">
                <Form.Select
                  id="filterBy"
                  value={searchBy}
                  onChange={handleSelect}
                >
                  {filterOptions.map((e) => (
                    <option key={e} value={e}>
                      {e}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Row} className="my-2" controlId="visitorMobile">
                <Form.Control
                  type="text"
                  placeholder="type here to search"
                  value={searchValue}
                  onChange={(e) => onSearchTextChange(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Row} className="my-2" controlId="visitorMobile">
                <Button
                  type="date"
                  placeholder="2023-11-27"
                  value={searchValue}
                  onChange={(e) => onSearchTextChange(e.target.value)}
                >
                  Search
                </Button>
              </Form.Group>
            </Col>
          </Row>
          <Row
            className="mb-3"
            style={{ margin: "auto", width: "95%", justifyContent: "center" }}
          >
            <Button
              className="marginPointEight fifteenWidth buttonGreen numbersButtonAssociateProfile"
              onClick={() => handleFilterSelecttion(totalUsers)}
            >
              Total <strong> {totalUsers?.length}</strong>
            </Button>

            <Button
              className="marginPointOne fifteenWidth buttonTeal numbersButtonAssociateProfile"
              onClick={() => handleFilterSelecttion(maleUsers)}
            >
              Male <strong> {maleUsers?.length}</strong>
            </Button>
            <Button
              className="marginPointOne fifteenWidth buttonMaroon numbersButtonAssociateProfile"
              onClick={() => handleFilterSelecttion(femaleUsers)}
            >
              Female <strong> {femaleUsers?.length}</strong>
            </Button>
            <Button
              className="marginPointOne fifteenWidth buttonOlive numbersButtonAssociateProfile"
              onClick={() => handleFilterSelecttion(seniorCitizens)}
            >
              Seniors <strong> {seniorCitizens?.length}</strong>
            </Button>
          </Row>
          {isLoading ? (
            <Loader />
          ) : error ? (
            <Message>{error?.data?.error}</Message>
          ) : (
            <div>
              <Table striped bordered hover responsive="sm">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Id</th>
                    <th>Subscription</th>
                  </tr>
                </thead>
                {usersToList ? (
                  <tbody>
                    {usersToList.map((user) => (
                      <tr key={user.userId}>
                        <td>{user.firstName} </td>
                        <td>{user.userId}</td>
                        <td>{user.subscriptionType}</td>
                        <td>
                          <button
                            style={{ borderRadius: "8%" }}
                            onClick={() => handleReview(user)}
                          >
                            Review
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <Message>No users Available</Message>
                )}
              </Table>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default PendingRegsScreen;
