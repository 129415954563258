import { apiSlice } from "./apiSlice";
import { ATTENDANCE_URL } from "../constants";

const attendanceSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateAttendance: builder.mutation({
      query: (data) => ({
        url: `${ATTENDANCE_URL}${data.params ? "/" + data.params : ""}`,
        method: "POST",
        body: data.payLoad,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),

    getAttendance: builder.query({
      query: (data) => ({
        url: `${ATTENDANCE_URL}${data.query ? data.query : ""}`,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),

    getAttendanceForUsers: builder.query({
      query: (data) => ({
        url: `${ATTENDANCE_URL}/dataForUsers${data.query ? data.query : ""}`,
      }),
    }),
    getAttendanceDashboard: builder.query({
      query: (data) => ({
        url: `${ATTENDANCE_URL}/dashboard${data.query ? data.query : ""}`,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
  }),
});

export const {
  useUpdateAttendanceMutation,
  useGetAttendanceQuery,
  useGetAttendanceForUsersQuery,
  useGetAttendanceDashboardQuery,
} = attendanceSlice;
