import { Button, Form, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useUpdateAssociateMutation } from "../slices/associatesSlice";
import VerticallyCenteredModal from "../components/SubmitModal";
import { getCurrentDate } from "../utils/getCurrentDate";
import FieldWarning from "../components/FieldWarning";

const AssociateEditScreen = ({ user, editStatus }) => {
  const [associateId, setAssociateId] = useState(user.associateId);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [gender, setGender] = useState(user.gender);
  const [mobileNumber, setMobileNumber] = useState(user.mobileNumber);
  const [alternateNumber, setAlternateNumber] = useState(user.alternateNumber);
  const [email, setEmail] = useState(user.email);
  const [panNumber, setPanNumber] = useState(user.panNumber);
  const [workShift, setWorkShift] = useState(
    user.workShift !== null ? user.workShift : 1
  );

  const [aadharNumber, setAadharNumber] = useState(user.aadharNumber);
  const [joinedDate, setJoinedDate] = useState(user.joinedDate?.split("T")[0]);
  const [dateOfBirth, setdDteOfBirth] = useState(
    user.dateOfBirth?.split("T")[0]
  );
  const [modalShow, setModalShow] = useState(false);

  const [pincode, setPincode] = useState(user.pincode);
  const [address, setAddress] = useState(user.address);

  const genderList = ["Male", "Female", "Other"];
  const workShifts = [1, 2, 3, 4];

  const [firstNamePresent, setFirstNamePresent] = useState(false);
  const [lastNamePresent, setLastNamePresent] = useState(false);
  const [genderPresent, setGenderPresent] = useState(false);
  const [mobileNumberPresent, setMobileNumberPresent] = useState(false);
  const [alternateNumberPresent, setAlternateNumberPresent] = useState(false);
  const [emailPresent, setEmailPresent] = useState(false);
  const [aadharNumberPresent, setAadharNumberPresent] = useState(false);
  const [panNumberPresent, setPanNumberPresent] = useState(false);
  const [joinedDatePresent, setJoinedDatePresent] = useState(false);
  const [dateOfBirthPresent, setDateOfBirthPresent] = useState(false);
  const [addressPresent, setAddressPresent] = useState(false);
  const [pincodePresent, setPincodePresent] = useState(false);

  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);
  const [updateUser, { isLoading }] = useUpdateAssociateMutation();

  // let { search } = useLocation();
  // const ps = new URLSearchParams(search);
  // const redirect = ps.get("redirect") || "/";

  useEffect(() => {
    if (firstName?.length > 0) {
      setFirstNamePresent(false);
    }
    if (lastName?.length > 0) {
      setLastNamePresent(false);
    }
    if (gender?.length > 1) {
      setGenderPresent(false);
    }

    if (mobileNumber?.length === 10 && mobileNumber > 1) {
      setMobileNumberPresent(false);
    }

    if (alternateNumber?.length === 10 || alternateNumber?.length === 0) {
      setAlternateNumberPresent(false);
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email) || email?.length === 0) {
      setEmailPresent(false);
    }

    if (aadharNumber?.length === 12 || aadharNumber?.length === 0) {
      setAadharNumberPresent(false);
    }

    if (panNumber?.length > 9 || panNumber?.length === 0) {
      setPanNumberPresent(false);
    }

    if (address?.length > 10 || address?.length === 0) {
      setAddressPresent(false);
    }

    if (pincode?.length === 6 || pincode?.length === 0) {
      setPincodePresent(false);
    }

    if (dateOfBirth < getCurrentDate()) {
      setDateOfBirthPresent(false);
    }

    if (joinedDate <= getCurrentDate()) {
      setJoinedDatePresent(false);
    }
  }, [
    firstName,
    lastName,
    gender,
    email,
    aadharNumber,
    dateOfBirth,
    joinedDate,
    address,
    associateId,
    mobileNumber,

    pincode,
    alternateNumber,
    panNumber,
  ]);

  const verifyInputFeilds = async () => {
    let fieldValidationSuccess = true;

    if (firstName?.length < 1 || firstName?.length > 100) {
      setFirstNamePresent(true);
      fieldValidationSuccess = false;
    }
    if (lastName?.length > 100) {
      setLastNamePresent(true);
      fieldValidationSuccess = false;
    }

    if (gender?.length < 1) {
      setGenderPresent(true);
      fieldValidationSuccess = false;
    }

    if (mobileNumber)
      if (mobileNumber?.toString().length !== 10 || mobileNumber < 1) {
        setMobileNumberPresent(true);
        fieldValidationSuccess = false;
      }
    if (alternateNumber)
      if (alternateNumber?.toString().length !== 10 || alternateNumber < 1) {
        setAlternateNumberPresent(true);
        fieldValidationSuccess = false;
      }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email)
      if (!emailRegex.test(email) || email?.length > 300) {
        setEmailPresent(true);
        fieldValidationSuccess = false;
      }
    if (aadharNumber)
      if (aadharNumber?.length !== 12 || Number(aadharNumber) < 0) {
        setAadharNumberPresent(true);
        fieldValidationSuccess = false;
      }
    if (panNumber)
      if (panNumber?.length < 8 || panNumber < 1) {
        setPanNumberPresent(true);
        fieldValidationSuccess = false;
      }

    if (address)
      if (address?.length < 10 || address?.length > 1000) {
        setAddressPresent(true);
        fieldValidationSuccess = false;
      }
    if (pincode)
      if (pincode?.toString().length !== 6 || pincode < 1) {
        setPincodePresent(true);
        fieldValidationSuccess = false;
      }

    if (!dateOfBirth || dateOfBirth >= getCurrentDate()) {
      setDateOfBirthPresent(true);
      fieldValidationSuccess = false;
    }

    if (joinedDate > getCurrentDate()) {
      setJoinedDatePresent(true);
      fieldValidationSuccess = false;
    }
    if (!fieldValidationSuccess)
      toast.error(
        "Some of the fields have invalid inputs. Please provide valid details"
      );
    return fieldValidationSuccess;
  };

  const submitHandler = async (e) => {
    // e.preventDefault();
    try {
      await updateUser({
        associateId,
        payload: {
          firstName,
          lastName,
          gender,
          mobileNumber,
          email,
          aadharNumber,
          pincode,
          workShift,
          panNumber,
          alternateNumber,
          joinedDate,
          dateOfBirth,
          address,
        },
        token: userInfo.token,
      }).unwrap();
      navigate(`/associate/${user.associateId}`);
      editStatus();
      toast.success("User Details Updated Successfully");
    } catch (e) {
      toast.error(e?.data?.message || e.data);
    }
  };

  const handleCancelButton = () => {
    navigate(`/associate/${user.associateId}`);
    editStatus();
  };

  const handleAssociateEdit = async () => {
    if (await verifyInputFeilds()) setModalShow(true);
  };

  return (
    <>
      <h5> Update Associate Details</h5>
      <VerticallyCenteredModal
        title="Are you sure want to update this ?"
        size="sm"
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSubmit={submitHandler}
        // setEdit={() => paymentStatus(true)}
      />

      <Row>
        <Col md={6}>
          <Form.Group as={Row} className="my-2" controlId="userId">
            <Form.Label column="true" sm="4">
              ID :
            </Form.Label>

            <Col sm="5">
              <Form.Label>{associateId}</Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="firtsName">
            <Form.Label column="true" sm="4">
              First Name :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="enter First Name"
              ></Form.Control>{" "}
              {firstNamePresent ? (
                <FieldWarning text="First name should have minimum 1 char and max 100 char" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="lastName">
            <Form.Label column="true" sm="4">
              Last Name :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="enter Last name"
              ></Form.Control>
              {lastNamePresent ? (
                <FieldWarning text="Last name can not have more than 100 characters" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="my-2" controlId="gender">
            <Form.Label column="true" sm="4">
              Select Gender :
            </Form.Label>
            <Col sm="5">
              <div className="horizontal-radio-group">
                {genderList.map((option) => (
                  <Form.Check
                    key={option}
                    type="radio"
                    label={option}
                    name="genderRadio"
                    id={`genderRadio-${option}`}
                    value={option}
                    checked={gender === option}
                    onChange={(e) => setGender(e.target.value)}
                  />
                ))}
              </div>
              {genderPresent ? (
                <FieldWarning text="Please select gender" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="workShift">
            <Form.Label column="true" sm="4">
              Work Shift:
            </Form.Label>
            <Col sm="5">
              <Form.Select
                value={workShift || 1}
                onChange={(e) => setWorkShift(e.target.value)}
                placeholder="Select work Shift "
              >
                {workShifts.map((e) => (
                  <option key={e} value={e}>
                    {e}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="mobileNumber">
            <Form.Label column="true" sm="4">
              Mobile Number :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={mobileNumber}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setMobileNumber(value);
                }}
                placeholder="enter mobile number"
              />
              {mobileNumberPresent ? (
                <FieldWarning text="Please provide 10 digit mobile number" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="alternateNumber">
            <Form.Label column="true" sm="4">
              Alternate Number :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={alternateNumber}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setAlternateNumber(value);
                }}
                placeholder="enter mobile number"
              />
              {alternateNumberPresent ? (
                <FieldWarning text="Please provide 10 digit mobile Number" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group as={Row} className="my-2" controlId="joinedDate">
            <Form.Label column="true" sm="4">
              Joined Date :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="date"
                value={joinedDate}
                onChange={(e) => setJoinedDate(e.target.value)}
                placeholder="in format 'YYYY-MM-DD'"
              ></Form.Control>
              {joinedDatePresent ? (
                <FieldWarning text="Joined date can not be future date" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="dateOfBirth">
            <Form.Label column="true" sm="4">
              Date Of Birth :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="date"
                value={dateOfBirth}
                onChange={(e) => setdDteOfBirth(e.target.value)}
                placeholder="in format 'YYYY-MM-DD'"
              ></Form.Control>
              {dateOfBirthPresent ? (
                <FieldWarning text="Please provide valid date of birth" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="email">
            <Form.Label column="true" sm="4">
              Email :
            </Form.Label>
            <Col sm="5">
              {" "}
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="enter email"
              ></Form.Control>
              {emailPresent ? (
                <FieldWarning text="Please provide valid e-mail" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="panNumber">
            <Form.Label column="true" sm="4">
              PAN Number :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="text"
                value={panNumber}
                onChange={(e) => setPanNumber(e.target.value)}
                placeholder="enter pan number"
              ></Form.Control>{" "}
              {panNumberPresent ? (
                <FieldWarning text="Please provide valid PAN number" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="aadharNumber">
            <Form.Label column="true" sm="4">
              Aadhar Number :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={aadharNumber}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setAadharNumber(value);
                }}
                placeholder="enter aadhar number"
              />
              {aadharNumberPresent ? (
                <FieldWarning text="Please provide 12 digit Aadhar number" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="my-2" controlId="address">
            <Form.Label column="true" sm="4">
              Address :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="enter Address"
              ></Form.Control>{" "}
              {addressPresent ? (
                <FieldWarning text="Address should be atleast 10 chars long" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="pincode">
            <Form.Label column="true" sm="4">
              Pincode :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={pincode}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setPincode(value);
                }}
                placeholder="enter 6 digit pincode"
              />
              {pincodePresent ? (
                <FieldWarning text="Please provide valid 6 digit Pincode" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>

          <Row column="true" sm="6" style={{ margin: "4%" }}>
            <Col sm="4"></Col>
            <Col sm="5">
              <Button
                type="submit"
                disabled={isLoading}
                variant="primary"
                onClick={handleAssociateEdit}
              >
                {" "}
                Submit
              </Button>
              <Button
                style={{ marginLeft: "20%" }}
                type="button"
                disabled={isLoading}
                variant="secondary"
                onClick={handleCancelButton}
              >
                {" "}
                Cancel
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AssociateEditScreen;
