import Modal from "react-bootstrap/Modal";
import { Button, Form, Row, Col } from "react-bootstrap";

function VerticallyCenteredModal(props) {
  const handleSubmit = async () => {
    props.onSubmit();
    props.onHide();
    if (props.setEdit) props.setEdit(false);
  };

  const handleCancel = async () => {
    props.onHide();
    if (props.setEdit) props.setEdit(false);
  };

  return (
    <Modal
      {...props}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <strong>{props.title}</strong>
        </Modal.Title>
      </Modal.Header>
      {props.payload ? (
        <Modal.Body>
          {props.payload ? (
            Object.keys(props.payload).map((key) => (
              <Form.Group
                as={Row}
                className="my-2"
                controlId="pendingAmount"
                key={key}
              >
                <Col sm="5"> {key} :</Col>
                <Col sm="5">{props.payload[key]}</Col>
              </Form.Group>
            ))
          ) : (
            <></>
          )}{" "}
        </Modal.Body>
      ) : (
        <></>
      )}
      <Modal.Footer>
        <Button onClick={handleSubmit}>Submit</Button>

        <Button onClick={handleCancel}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default VerticallyCenteredModal;
