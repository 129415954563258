import { apiSlice } from "./apiSlice";
import { ASSOCIATES_URL } from "../constants";

const associatesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    associateLogin: builder.mutation({
      query: (data) => ({
        url: `${ASSOCIATES_URL}/login`,
        method: "POST",
        body: data,
      }),
    }),
    associateLogout: builder.mutation({
      query: () => ({
        url: `${ASSOCIATES_URL}/logout`,
        method: "POST",
      }),
    }),
    registerOwner: builder.mutation({
      query: (data) => ({
        url: `${ASSOCIATES_URL}/createOwner`,
        method: "POST",
        body: data,
      }),
    }),
    registerAssociate: builder.mutation({
      query: (data) => ({
        url: `${ASSOCIATES_URL}`,
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    getAssociates: builder.query({
      query: (body) => ({
        url: ASSOCIATES_URL,
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    getAssociateById: builder.query({
      query: (body) => ({
        url: `${ASSOCIATES_URL}/${body.params}`,
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    getMyDetails: builder.query({
      query: (body) => ({
        url: `${ASSOCIATES_URL}/myDetails`,
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    updateAssociate: builder.mutation({
      query: (data) => ({
        url: `${ASSOCIATES_URL}/${data.associateId}`,
        method: "PUT",
        body: data.payload,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    updateMyDetails: builder.mutation({
      query: (data) => ({
        url: `${ASSOCIATES_URL}/myDetails`,
        method: "PUT",
        body: data,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    changeAssociateRole: builder.mutation({
      query: (data) => ({
        url: `${ASSOCIATES_URL}/${data.associateId}/updateRole`,
        method: "PUT",
        body: data.payload,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: `${ASSOCIATES_URL}/${data.associateId}/changePassword`,
        method: "PUT",
        body: data.payload,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    changeAssociatePassword: builder.mutation({
      query: (data) => ({
        url: `${ASSOCIATES_URL}/changeAssociatePassword`,
        method: "PUT",
        body: data.payload,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    deleteAssociate: builder.mutation({
      query: (data) => ({
        url: `${ASSOCIATES_URL}/${data.associateId}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: `${ASSOCIATES_URL}/resetPassword/${data.associateId}`,
        method: "PUT",
        body: data.payload,
      }),
    }),
  }),
});

export const {
  useAssociateLoginMutation,
  useAssociateLogoutMutation,
  useRegisterAssociateMutation,
  useGetAssociatesQuery,
  useGetAssociateByIdQuery,
  useUpdateAssociateMutation,
  useDeleteAssociateMutation,
  useChangeAssociateRoleMutation,
  useChangePasswordMutation,
  useRegisterOwnerMutation,
  useUpdateMyDetailsMutation,
  useGetMyDetailsQuery,
  useChangeAssociatePasswordMutation,
  useResetPasswordMutation,
} = associatesApiSlice;
