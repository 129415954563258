import { apiSlice } from "./apiSlice";
import { WS_MESSAGE_URL } from "../constants";

const wsMessageSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendWsMessage: builder.mutation({
      query: (data) => ({
        url: `${WS_MESSAGE_URL}${data?.params ? "/" + data.params : ""}`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${data?.token}`,
        },
      }),
    }),

    getWsMessage: builder.query({
      query: (data) => ({
        url: `${WS_MESSAGE_URL}${data?.query ? data.query : ""}`,
        headers: {
          Authorization: `Bearer ${data?.token}`,
        },
      }),
    }),
  }),
});

export const { useSendWsMessageMutation, useGetWsMessageQuery } =
  wsMessageSlice;
