import { useSelector } from "react-redux";
import QRCodeGenerator from "../utils/createQRcode";
import FormContainer from "../components/FormContainer";

const QrCodeScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const qrContent = `https://zpotter.in/attendanceFromMobile/${userInfo.gymId._id}`;

  return (
    <FormContainer>
      <h4>Scan and provide user ID to mark In or Out Time</h4>
      <QRCodeGenerator content={qrContent} />
    </FormContainer>
  );
};

export default QrCodeScreen;
