import React from "react";
import { Line } from "react-chartjs-2";

const LineChart = ({ chartData }) => {
  const screenWidth = window.innerWidth;
  return (
    <div className="lineChart">
      <p style={{ color: "red" }}>{chartData.labelText?.split("...")[1]}</p>
      <Line
        height={screenWidth < 500 && "250px"}
        data={chartData}
        options={{
          plugins: {
            title: {
              display: true,
              text: "",
            },
            legend: {
              display: false,
            },
          },
          responsive: true,
        }}
      />
      <label>{chartData.labelText?.split("...")[0]}</label>
    </div>
  );
};
export default LineChart;
