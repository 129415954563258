import { React, useEffect, useState } from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx"; // Importing xlsx library
import { toast } from "react-toastify";

import { useGetUsersQuery } from "../slices/usersSlice";
import Loader from "../components/Loader";
import { setUsers } from "../slices/userSlice";
import Message from "../components/Message";
import { setCredentials } from "../slices/authSlice";

const UsersScreen = () => {
  const [searchBy, setSearchBy] = useState("Id");
  const [searchValue, setSearchValue] = useState("");
  const { userInfo } = useSelector((state) => state.auth);

  const filterOptions = ["Id", "Name", "Mobile"];

  const dispatch = useDispatch();

  const searchOptions = {
    Id: "_id",
    Name: "firstName",
    Mobile: "mobileNumber",
  };
  let {
    data: users,
    isLoading,
    refetch,
    error,
  } = useGetUsersQuery({
    query: "?sort=subscriptionExpiryDate",
    payLoad: userInfo.gymId._id,
    token: userInfo.token,
  });

  const [totalUsers, setTotalUsers] = useState(
    isLoading ? [] : users ? users : []
  );
  const [usersToList, setUsersToList] = useState(
    isLoading ? [] : users ? users : []
  );
  const [activeUsers, setActiveUsers] = useState("");
  const [inActiveUsers, setInactiveUsers] = useState("");
  const [maleUsers, setMaleUsers] = useState("");
  const [femaleUsers, setfemaleUsers] = useState("");
  const [seniorCitizens, setSeniorCitizens] = useState("");
  const [ptEnablesUsers, setPtEnablesUsers] = useState("");

  useEffect(() => {
    refetch();
    if (users) dispatch(setUsers(users));
    dispatch(
      setCredentials({
        ...userInfo,
        lastVisitedPage: "/users",
      })
    );
    setTotalUsers(isLoading ? [] : users ? users : 0);
    setUsersToList(isLoading ? [] : users ? users : 0);
    setActiveUsers(
      isLoading ? [] : users ? users.filter((user) => user["isActive"]) : []
    );

    setInactiveUsers(
      isLoading ? [] : users ? users.filter((user) => !user["isActive"]) : []
    );

    setMaleUsers(
      isLoading
        ? []
        : users
        ? users.filter((user) => user["gender"] === "Male")
        : []
    );

    setfemaleUsers(
      isLoading
        ? []
        : users
        ? users.filter((user) => user["gender"] === "Female")
        : []
    );
    setSeniorCitizens(
      isLoading
        ? []
        : users
        ? users.filter(
            (user) =>
              50 <
              Math.floor(
                (new Date() - new Date(user["dateOfBirth"])) /
                  (365.25 * 24 * 60 * 60 * 1000)
              )
          )
        : []
    );

    setPtEnablesUsers(
      isLoading ? [] : users ? users.filter((user) => user["ptEnabled"]) : []
    );
  }, [refetch, dispatch, users]);

  const handleSelect = (e) => {
    setSearchBy(e.target.value);
  };
  const onSearchTextChange = (val) => {
    setSearchValue(val);
    const searchedUsers = users?.filter((user) => {
      let userData;
      if (searchBy === "Name") {
        userData = String(user[searchOptions[searchBy]]).toLowerCase();
      } else {
        userData = String(user[searchOptions[searchBy]]);
      }
      return userData.includes(val.toLowerCase());
    });
    setUsersToList(searchedUsers);
  };
  // const handleSearchButton = () => {
  //   const searchedUsers = users?.filter((user) => {
  //     let userData;
  //     if (searchBy === "Name") {
  //       userData = String(user[searchOptions[searchBy]]).toLowerCase();
  //     } else {
  //       userData = String(user[searchOptions[searchBy]]);
  //     }
  //     return userData.includes(searchValue.toLowerCase());
  //   });
  //   setUsersToList(searchedUsers);
  // };

  const handleFilterSelecttion = (val) => {
    setUsersToList(val);
  };

  const exportToExcel = () => {
    if (error) {
      toast.error(
        "No Payments available to Download for the selected Date range"
      );
    } else {
      const formattedData = users?.map((user) => ({
        "User Id": user.userId,
        "First Name": user.firstName,
        "Last Name": user.lastName,
        Gender: user.gender,
        "PT Enabled": user.ptEnabled,
        "Subscription Months": user.subscriptionType,
        "Subscription Amount": user.subscriptionAmount,
        "Paid Amount": user.paidAmount,
        "Pending Amount": user.pendingAmount,
        "Recent Paid Amount": user.lastPaidAmount,
        "Recent Paid Date": user.lastPaidDate
          ? user.lastPaidDate.split("T")[0]
          : user.lastPaidDate.split("T")[0],

        "Joined Date": user.joinedDate
          ? user.joinedDate.split("T")[0]
          : user.joinedDate.split("T")[0],
        "Renewed Date": user.renewedDate
          ? user.renewedDate.split("T")[0]
          : user.renewedDate.split("T")[0],
        "Expiry Date": user.subscriptionExpiryDate
          ? user.subscriptionExpiryDate.split("T")[0]
          : user.subscriptionExpiryDate.split("T")[0],
        "Mobile Number": user.mobileNumber,
        "Aadhar Number": user.aadharNumber,
        "Heigth In Cm": user.heigthInCm,
        "Weight In Kg": user.weightInKg,
        Email: user.email,
        "Date of Birth": user.dateOfBirth
          ? user.dateOfBirth.split("T")[0]
          : user.dateOfBirth.split("T")[0],

        "Gym Goal": user.gymGoal[0],
        isActive: user.isActive,
        Address: user.address,
        "Associate Id": user.associateId,
      }));

      const workSheet = XLSX.utils.json_to_sheet(formattedData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Users List");
      XLSX.writeFile(workBook, "Users List.xlsx");
    }
  };

  return (
    <>
      <h5>Users List</h5>
      <Row
        className="mb-3"
        style={{
          justifyContent: "center",
          padding: "auto",
          margin: "auto",
          width: "95%",
          alignItems: "center",
        }}
      >
        <Col>
          <Form.Group as={Row} className="my-2" controlId="filterBy">
            <Form.Select id="filterBy" value={searchBy} onChange={handleSelect}>
              {filterOptions.map((e) => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Row} className="my-2" controlId="visitorMobile">
            <Form.Control
              type="text"
              placeholder="type here to search"
              value={searchValue}
              onChange={(e) => onSearchTextChange(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Row} className="my-2" controlId="visitorMobile">
            <Button
              type="date"
              placeholder="2023-11-27"
              value={searchValue}
              onChange={(e) => onSearchTextChange(e.target.value)}
            >
              Search
            </Button>
          </Form.Group>
        </Col>
        <Col xs={6} md="auto" sm={6} className="d-flex justify-content-center">
          <Button onClick={exportToExcel} variant="success">
            Download Users
          </Button>
        </Col>
      </Row>
      <Row className="dataRow" style={{ marginBottom: "2%" }}>
        <Button
          className="marginPointEight fifteenWidth buttonGreen numbersButton"
          onClick={() => handleFilterSelecttion(totalUsers)}
        >
          All <strong> {totalUsers?.length}</strong>
        </Button>
        <Button
          className="marginPointOne fifteenWidth buttonPeach numbersButton"
          onClick={() => handleFilterSelecttion(activeUsers)}
        >
          Active <strong> {activeUsers?.length}</strong>
        </Button>
        <Button
          className="marginPointOne fifteenWidth buttonReddish numbersButton"
          onClick={() => handleFilterSelecttion(inActiveUsers)}
        >
          InActive <strong> {inActiveUsers?.length}</strong>
        </Button>
        <Button
          className="marginPointOne fifteenWidth buttonTeal numbersButton"
          onClick={() => handleFilterSelecttion(maleUsers)}
        >
          Male <strong> {maleUsers?.length}</strong>
        </Button>
        <Button
          className="marginPointOne fifteenWidth buttonMaroon numbersButton"
          onClick={() => handleFilterSelecttion(femaleUsers)}
        >
          Female <strong> {femaleUsers?.length}</strong>
        </Button>
        <Button
          className="marginPointOne fifteenWidth buttonOlive numbersButton"
          onClick={() => handleFilterSelecttion(seniorCitizens)}
        >
          Seniors <strong> {seniorCitizens?.length}</strong>
        </Button>
        <Button
          className="marginPointOne fifteenWidth buttonLoveBlue numbersButton"
          onClick={() => handleFilterSelecttion(ptEnablesUsers)}
        >
          PT-User <strong> {ptEnablesUsers?.length}</strong>
        </Button>
      </Row>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message>{error?.data?.error}</Message>
      ) : (
        <div>
          <Table striped bordered hover responsive="sm">
            <thead>
              <tr key="table-header">
                <th>Name</th>
                <th>Id</th>
                <th>Subscription</th>
                {/* <th>Status</th> */}
                <th>Valid</th>
              </tr>
            </thead>
            {usersToList ? (
              <tbody>
                {usersToList.map((user) => (
                  <tr key={user.userId}>
                    <td>
                      <Link to={`/users/${user.userId}`}>{user.firstName}</Link>
                    </td>
                    <td>{user.userId}</td>

                    <td> {user.subscriptionType}</td>
                    {/* <td> {user.isActive ? "Active" : "Not Active"}</td> */}
                    <td style={{ whiteSpace: "nowrap" }}>
                      {user.subscriptionExpiryDate
                        ? user.subscriptionExpiryDate?.split("T")[0]
                        : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <Message>No users Available</Message>
            )}
          </Table>
        </div>
      )}
    </>
  );
};

export default UsersScreen;
