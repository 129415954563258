import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { getCurrentDate } from "../utils/getCurrentDate";

import { setCredentials } from "../slices/authSlice";
import VerticallyCenteredModal from "../components/SubmitModal";
import {
  useGetWsMessageQuery,
  useSendWsMessageMutation,
} from "../slices/wsMessageSlice";

const WsMessageListScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [startDate, setStartDate] = useState(
    userInfo?.wsMessageStartDate
      ? userInfo?.wsMessageStartDate
      : getCurrentDate()
  );

  const [endDate, setEndDate] = useState(
    userInfo?.wsMessageEndDate ? userInfo?.wsMessageEndDate : getCurrentDate()
  );

  const [getApiQuery, setGetApiQuery] = useState(
    `?gymId=${userInfo.gymId._id}&createdAt[gte]=${startDate}T00:00:00.125Z&createdAt[lte]=${endDate}T23:59:59.125Z&fields=gymId, userId, mobileNumber, messageContent, typeOfMessage, createdAt,-_id&sort=-createdAt`
  );

  const [modalShow, setModalShow] = useState(false);
  const [reminderType, setReminderType] = useState("");

  const {
    data: wsMessages,
    isLoading,
    error,
    refetch,
  } = useGetWsMessageQuery({
    query: getApiQuery,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  const [sendReminder] = useSendWsMessageMutation();

  const [messagesToList, setMessagesToList] = useState(
    isLoading ? [] : wsMessages ? wsMessages : []
  );

  const [todaysMessages, setTodaysMessages] = useState(
    isLoading ? [] : wsMessages ? wsMessages.length : 0
  );
  const [renewalMessages, setRenewalMessages] = useState("");
  const [inActiveMessages, setInActiveMessages] = useState("");

  const dispatch = useDispatch();

  const handleGetUsers = async () => {
    if (startDate > getCurrentDate())
      toast.error("Start date can not be greater than today");
    else if (startDate > endDate)
      toast.error("Start date can not be greater than End date");
    else {
      // if (endDate?.split("T")[0] > getCurrentDate())
      //   setEndDate(getCurrentDate() + getCurrentTime());
      setGetApiQuery(
        `?gymId=${userInfo.gymId._id}&createdAt[gte]=${startDate}T00:00:00.125Z&createdAt[lte]=${endDate}T23:59:59.125Z&fields=gymId, userId, mobileNumber, messageContent, typeOfMessage, createdAt,-_id&sort=-createdAt`
      );
      dispatch(
        setCredentials({
          ...userInfo,
          wsMessageStartDate: startDate,
          wsMessageEndDate: endDate,
        })
      );
    }
  };

  const initiateReminder = async (endPoint) => {
    setReminderType(endPoint);
    setModalShow(true);
  };
  const sendWsReminder = async () => {
    try {
      const resData = await sendReminder({
        token: userInfo.token,
        params: reminderType,
      }).unwrap();
      toast.success(resData.Message);
      setReminderType("");
    } catch (e) {
      toast.error(e?.data?.error || e.data);
    }
  };
  // const sendInActiveReminder = async () => {
  //   try {
  //     const resData = await sendReminder({
  //       token: userInfo.token,
  //       params: "sendInActiveReminder",
  //     }).unwrap();
  //     toast.success(resData.Message);
  //   } catch (e) {
  //     toast.error(e?.data?.error || e.data);
  //   }
  // };

  useEffect(() => {
    const todaysMessages = isLoading ? [] : wsMessages ? wsMessages : [];
    setTodaysMessages(todaysMessages);
    setMessagesToList(todaysMessages);

    const renewalMsg = isLoading
      ? []
      : wsMessages
      ? wsMessages?.filter((msg) => msg?.typeOfMessage === "Renewal")
      : [];

    const inActiveMsg = isLoading
      ? []
      : wsMessages
      ? wsMessages?.filter((msg) => msg?.typeOfMessage === "InActive")
      : [];

    setRenewalMessages(renewalMsg);
    setInActiveMessages(inActiveMsg);
  }, [setTodaysMessages, wsMessages, isLoading]);

  const handleFilterSelecttion = (val) => {
    setMessagesToList(val);
  };
  const handleSubmit = async () => {
    await sendWsReminder();
  };

  return (
    <>
      <h5>
        WhatsApp Message List{" "}
        {/* <button
          style={{ color: "red" }}
          onClick={(e) => initiateReminder("sendRenewalReminder")}
        >
          Send Renewal Reminder
        </button>
        <button
          style={{ color: "green" }}
          onClick={(e) => initiateReminder("sendInActiveReminder")}
        >
          Send InActive Reminder
        </button> */}
      </h5>{" "}
      <VerticallyCenteredModal
        title="Want to Send whatsApp message ?"
        show={modalShow}
        size="sm"
        onHide={() => setModalShow(false)}
        onSubmit={handleSubmit}
      />
      <Row
        className="mb-1"
        style={{
          justifyContent: "center",
          margin: "auto",
          width: "95%",
          alignItems: "center",
        }}
      >
        <Col xs={6} md="auto" sm={6}>
          <Form.Group controlId="visitorMobile">
            <Form.Control
              type="date"
              placeholder="2023-11-23"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            ></Form.Control>{" "}
          </Form.Group>
        </Col>
        <Col xs={6} md="auto" sm={6}>
          <Form.Group controlId="visitorMobile">
            <Form.Control
              type="date"
              placeholder="2023-11-27"
              value={endDate?.split("T")[0]}
              onChange={(e) => setEndDate(e.target.value)}
            ></Form.Control>{" "}
          </Form.Group>
        </Col>
        <Col xs={12} md="auto" sm={6} className="d-flex justify-content-center">
          <Form.Group className="my-1" controlId="gymType">
            <Button onClick={handleGetUsers}>Get Messages</Button>
          </Form.Group>
        </Col>
      </Row>
      {userInfo.role === "owner" ||
      userInfo.role === "Super Admin" ||
      userInfo.role === "admin" ? (
        <>
          <Row
            className="mb-1"
            style={{
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <Button
              className="marginPointOne sixteenWidth buttonGreen numbersButton"
              onClick={() => handleFilterSelecttion(todaysMessages)}
            >
              Total Messages<strong> {todaysMessages?.length}</strong>
            </Button>
            <Button
              className="marginPointOne sixteenWidth buttonTeal numbersButton"
              onClick={() => handleFilterSelecttion(renewalMessages)}
            >
              Renewal <strong> {renewalMessages?.length}</strong>
            </Button>
            <Button
              className="marginPointOne sixteenWidth buttonPeach numbersButton"
              onClick={() => handleFilterSelecttion(inActiveMessages)}
            >
              InActive <strong> {inActiveMessages?.length}</strong>
            </Button>
          </Row>
        </>
      ) : (
        ""
      )}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message>{error?.data?.error}</Message>
      ) : messagesToList ? (
        <div>
          <Table striped bordered hover responsive="sm">
            <thead>
              <tr>
                <th style={{ whiteSpace: "nowrap" }}>User Id</th>
                <th>Mobile</th>
                <th style={{ whiteSpace: "nowrap" }}>Message Type</th>
                <th>Date</th>
                {/* <th>Message</th> */}
              </tr>
            </thead>
            <tbody>
              {messagesToList?.map((msg) => (
                <tr>
                  <td
                    style={{
                      borderTopLeftRadius: "30%",
                    }}
                  >
                    {msg?.userId}
                  </td>
                  <td
                    style={{
                      color:
                        msg?.mobileNumber?.toString().length !== 10
                          ? "red"
                          : "",
                    }}
                  >
                    {msg?.mobileNumber}
                  </td>
                  <td>{msg?.typeOfMessage}</td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {" "}
                    {msg?.createdAt?.split("T")[0]}
                  </td>
                  {/* <td style={{ borderBottomRightRadius: "30%" }}>
                    {" "}
                    {msg?.messageContent}
                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default WsMessageListScreen;
